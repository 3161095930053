import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { checkPersonId } from 'utils/KubenAssessments/checkPersonId';
import { chosenTemplate } from 'state/global/templates';
import { chosenRegion } from 'state/KubenAssessments/choosingTemplate/region';
import { selectedPerson } from 'state/KubenAssessments/saved/savedSelectedPerson';
import { templateAssessment } from 'state/KubenAssessments/assessments/templateAssessment';
import { chosenAssessmentAction } from 'state/KubenAssessments/chosenAssessmentAction/chosenAssessmentAction';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';
import { currentQuestionState } from 'state/KubenAssessments/questions/currentQuestions';

import useFetchSelectedPerson from 'hooks/api/person/useFetchSelectedPerson';
import useCreateNewPerson from 'hooks/api/person/useCreateNewPerson';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';

import ChoosePersonForm from 'components/KubenAssessments/ChoosePersonPage/ChoosePersonForm';
import ChoosePersonHeading from 'components/KubenAssessments/ChoosePersonPage/ChoosePersonHeading';
import ChoosePersonNavigation from 'components/KubenAssessments/ChoosePersonPage/ChoosePersonNavigation';

const ChoosePersonPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // reset recoil state
  const resetSelectedPerson = useResetRecoilState(selectedPerson);
  const resetSelectedTemplate = useResetRecoilState(chosenTemplate);
  const resetTemplateAssessment = useResetRecoilState(templateAssessment);
  const resetSelectedRegion = useResetRecoilState(chosenRegion);
  const resetChosenAssessment = useResetRecoilState(chosenAssessmentAction);
  const resetAnswersAssessment = useResetRecoilState(answersAssessment);
  const resetCurrentQuestion = useResetRecoilState(currentQuestionState);
  const person = useRecoilValue(selectedPerson);

  // form mechanism
  const [showServerErrorModal, setShowServerErrorModal] = useState(false);
  const [personIdType, setPersonIdType] = useState('');
  const [isCreatingPerson, setIsCreatingPerson] = useState(false);
  const [choosePersonParameters, setChoosePersonParameters] = useState({
    socialNumber: null,
    customerNumber: null,
    personId: null,
    reportId: null,
  });
  const [formValues, setFormValues] = useState({
    personId: '',
    personFullName: '',
    gender: '',
    birthYear: '',
  });
  const [formErrorMessages, setFormErrorMessages] = useState({
    personId: '',
  });

  // fetching data from api
  const {
    isLoading: isChoosePersonLoading,
    isError: isChoosePersonError,
    isSuccess: isChoosePersonSuccess,
    error: errorChoosePersonData,
    mutate: mutateChoosePerson,
  } = useFetchSelectedPerson(choosePersonParameters);
  const {
    isLoading: isPersonCreateLoading,
    isError: isPersonCreateError,
    isSuccess: isCreatePersonSuccess,
    mutate: mutateCreatePerson,
  } = useCreateNewPerson(formValues, personIdType);

  const onChange = (e, inputName, inputValue) => {
    // handle passing from inputs and dropdown component
    if (e) {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
    } else {
      setFormValues({ ...formValues, [inputName]: inputValue });
    }
  };

  // reset recoil data: selected person, chosen template, region
  useEffect(() => {
    resetSelectedPerson();
    resetSelectedTemplate();
    resetSelectedRegion();
    resetChosenAssessment();
    resetTemplateAssessment();
    resetAnswersAssessment();
    resetCurrentQuestion();
  }, []);

  // set userId types, fetchPersonChooseParameters, resetForm in case of change provided userId
  useEffect(() => {
    const assignedPersonIdType = checkPersonId(formValues.personId);
    setPersonIdType(assignedPersonIdType);
    setChoosePersonParameters({
      socialNumber: null,
      customerNumber: null,
      personId: null,
      reportId: null,
      [assignedPersonIdType]: formValues.personId,
    });
    setFormErrorMessages({ personId: '' });
    setIsCreatingPerson(false);
  }, [formValues.personId]);

  useEffect(() => {
    if (isChoosePersonError) {
      if (errorChoosePersonData.response?.data === 'Person not found') {
        setIsCreatingPerson(true);
        setFormErrorMessages({
          ...formErrorMessages,
          personId: `${t('ChoosePersonPage.personNotFoundValidationMessage')}`,
        });
      } else {
        setShowServerErrorModal(true);
      }
    }
  }, [isChoosePersonError]);

  useEffect(() => {
    if (isChoosePersonSuccess || isCreatePersonSuccess) {
      history.push('/assessments/person/' + person?.id);
    }
  }, [isChoosePersonSuccess, isCreatePersonSuccess]);

  const onSubmit = () => {
    if (validateForm()) {
      if (!isCreatingPerson) {
        mutateChoosePerson();
      } else {
        mutateCreatePerson();
      }
    }
  };

  const validateForm = () => {
    const errors = {
      personId: '',
    };
    if (!personIdType) {
      errors.personId = `${t('ChoosePersonPage.wrongIdValidationMessage')}`;
    }
    setFormErrorMessages(errors);
    const formMessagesWithError = Object.entries(errors).filter(([key, value]) => value.length > 0);
    return formMessagesWithError.length == 0;
  };

  const checkAvailabilityToProceed = () => {
    if (!isCreatingPerson) {
      return formValues.personId?.length == 12 || formValues.personId.length == 32;
    }
    // required fields for customerNumber
    if (personIdType === 'customerNumber') {
      return formValues.personId && formValues.gender && formValues.birthYear && formValues.personFullName
        ? true
        : false;
    }
    // cant create person with reportId
    if (personIdType === 'reportId') return false;
    // required field for socialNumber, personId
    return formValues.personFullName ? true : false;
  };

  if (isChoosePersonLoading || isPersonCreateLoading) {
    return <UILoadingModal />;
  }

  if (showServerErrorModal || isPersonCreateError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper>
      <div className="flex flex-col w-3/5 max-w-[720px] mt-10 mx-auto">
        <ChoosePersonHeading />
        <div className="mt-16 mb-10 choosePersonWrapper">
          <ChoosePersonForm
            formValues={formValues}
            formErrorMessages={formErrorMessages}
            onChange={onChange}
            isCreatingPerson={isCreatingPerson}
            personIdType={personIdType}
            onSubmit={onSubmit}
          />
        </div>
        <ChoosePersonNavigation
          isEnabled={checkAvailabilityToProceed()}
          submit={onSubmit}
        />
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default ChoosePersonPage;
