export const getInitials = function (userName) {
  const trimmedName = userName.trim();
  const whiteSpaceIndex = trimmedName?.lastIndexOf(' ');
  if (whiteSpaceIndex > 0) {
    return trimmedName?.substring(0, 1) + '.' + trimmedName?.substring(whiteSpaceIndex + 1, whiteSpaceIndex + 2);
  } else return trimmedName?.substring(0, 3);
};

export const getFirstLetters = function (userName) {
  return userName
    .trim()
    .split(/\s+/)
    .filter((word) => word)
    .map((word) => word[0].toUpperCase())
    .join('');
};
