import client from './clients/base';

const URL = 'user/';

const userSearch = async (page, pageSize, body) => {
  const response = await client.post(`${URL}search/${page}/${pageSize}`, body);
  return response.data;
};

const userDetails = async (id) => {
  const response = await client.get(`${URL}load/${id}`);
  return response.data;
};

const userEdit = async (body) => {
  const response = await client.post(`${URL}save`, body);
  return response.data;
};

const userActivity = async (id, page, pageSize) => {
  const response = await client.get(`${URL}load-activity/${id}/${page}/${pageSize}`);
  return response.data;
};

const fetchUserRoles = async () => {
  const response = await client.get(`${URL}load-roles`);
  return response.data;
};

const fetchUserRegions = async () => {
  const response = await client.get(`${URL}regions`);
  return response.data;
};

const fetchUserDashboardComponents = async () => {
  const userDashboardComponents = await client.get(`${URL}landing-page-components`);
  return userDashboardComponents.data;
};

const saveUserProfile = async (pinnedLandingPageComponents, pinnedDashboardComponents) => {
  const saveUserProfile = await client.post(`${URL}save-profile`, {
    PinnedLandingPageComponentIds: pinnedLandingPageComponents,
    PinnedDashboardComponents: pinnedDashboardComponents,
  });
  return saveUserProfile.data;
};

const fetchThemeColors = async () => {
  const themeColors = await client.get(`${URL}themes`);
  return themeColors.data;
};

const saveUserTheme = async (data) => {
  const response = await client.post(`${URL}set-theme`, data);
  return response.data;
};

const setUserDefaultRegion = async (regionId) => {
  const response = await client.post(`${URL}set-default-region`, regionId);
  return response.data;
};

const fetchUserTags = async (regionId) => {
  const response = await client.get(`${URL}tags/${regionId}`);
  return response.data;
};

const actions = {
  userSearch,
  userDetails,
  userEdit,
  saveUserTheme,
  userActivity,
  fetchUserRoles,
  fetchUserRegions,
  fetchUserDashboardComponents,
  saveUserProfile,
  fetchThemeColors,
  fetchUserTags,
  setUserDefaultRegion,
};

export default actions;
