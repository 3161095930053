import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { useRecoilState, useRecoilValue } from 'recoil';

import filterPeriods from 'utils/KubenAnalytics/filterPeriods';
import parseToCSV from 'utils/KubenAnalytics/parseToCSV';
import { currentUser as currentUserRecoil } from 'state/global/currentUser';
import { usersPagesLength, usersPageState } from 'state/KubenAnalytics/users/usersPages';
import { usersStats } from 'state/KubenAnalytics/users/usersValues';
import { usersSorted, usersPaginated } from 'state/KubenAnalytics/users/users';
import { usersFilters } from 'state/KubenAnalytics/users/usersFilters';
import { currentSelection } from 'state/KubenAnalytics/current/currentSelection';
import { currentAnalyticsModel } from 'state/KubenAnalytics/analytics/models';
import {
  usersPeriods as userPeriodsRecoil,
  usersDateRange,
  usersPeriodsType,
} from 'state/KubenAnalytics/users/usersPeriods';

import { fetchUsers } from 'hooks/api/analytics/useFetchUsers';
import { defaultColumns } from 'config/constants';

import UIContainerCard from 'components/global/UICommon/UIContainerCard';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';

import UIStatsCard from 'components/KubenAnalytics/UIAnalytics/UIStatsCard';
import UIStatsCardContainer from 'components/KubenAnalytics/UIAnalytics/UIStatsCardContainer';
import UIDownload from 'components/KubenAnalytics/UIAnalytics/UIDownload';

import AppListviewDataTable from 'components/KubenAnalytics/AppListview/AppListviewDataTable';
import AppListviewPagination from 'components/KubenAnalytics/AppListview/AppListviewPagination';
import AppListViewFilters from 'components/KubenAnalytics/AppListview/AppListViewFilters';

const PageList = () => {
  const analyticModel = useRecoilValue(currentAnalyticsModel);
  const selection = useRecoilValue(currentSelection);
  // data needed to set date
  const usersPeriods = useRecoilValue(userPeriodsRecoil);
  const dateType = useRecoilValue(usersPeriodsType);
  const customDateRange = useRecoilValue(usersDateRange);
  const dateRange = filterPeriods(dateType, usersPeriods.labels, usersPeriods.years, customDateRange);
  const selectionWithDates = {
    ...selection,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  };
  // data needed to set date
  const { isLoading, isError, refetch } = fetchUsers(selectionWithDates, analyticModel);
  const users = useRecoilValue(usersPaginated);
  const allUsers = useRecoilValue(usersSorted);
  const [page, setPage] = useRecoilState(usersPageState);
  const [filters, setFilters] = useRecoilState(usersFilters);
  const totalPages = useRecoilValue(usersPagesLength);
  const stats = useRecoilValue(usersStats);
  const csvData = parseToCSV(allUsers);

  // redirecting to selected person assessment page
  const currentUser = useRecoilValue(currentUserRecoil);

  const onChangeFilter = (field, value) => {
    const tmpFilters = { ...filters };
    tmpFilters[field] = value;
    setFilters(tmpFilters);
  };

  useEffect(() => {
    document.title = 'Kuben Analytics - Lista';
  }, []);

  if (isLoading) {
    return <UILoadingModal customMessage="Samlar data från ditt urval.." />;
  }

  if (isError) {
    return <div>Error...</div>;
  }

  return (
    <>
      <UIStatsCardContainer>
        <UIStatsCard
          title="Antal brukare"
          number={stats.users}
          value="st"
          tooltip={'Antalet brukare som visas i grafen baserat på gjorda val'}
        />
        <UIStatsCard
          title="Antal timmar"
          number={Math.round(stats.hours)}
          value="tim"
          tooltip={'Totalt antal timmar inom vald kategori och period'}
        />
        <UIStatsCard
          title="Ersättning"
          number={Math.round(stats.costs)}
          value="kr/mån"
          tooltip={'Ersättning'}
        />
        <UIStatsCard
          title="Minskade behov"
          number={stats.decreases}
          value="st"
          tooltip={'Antalet brukare där trenden visar ett minskat behov'}
        />
        <UIStatsCard
          title="Ökade behov"
          number={stats.increases}
          value="st"
          tooltip={'Antalet brukare där trenden visar ett ökat behov'}
        />
        <UIStatsCard
          title="Oförändrade behov"
          number={stats.steady}
          value="st"
          tooltip={'Antalet brukare där trenden visar ett oförändrat behov'}
        />
        <UIStatsCard
          title="Oförutsedd utveckling"
          number={stats.unpredictables}
          value="st"
          tooltip={'Antalet brukare där trenden visar en oförutsedd utveckling'}
        />
      </UIStatsCardContainer>
      <UIContainerCard>
        <div className="flex flex-col justify-between h-full">
          <div className="overflow-x-scroll pt-2 table-scroll ml-5 h-full max-w-96 w-full mr-auto">
            <div className="flex items-center pb-4 pt-2 sticky top-0 left-0">
              <Link
                className="background-secondary rounded p-2 shadow-md go-back"
                to="/analytics/results"
              >
                <FaArrowLeft className="icon" />
              </Link>
              <h2 className="font-bold text-left pl-2 text">Samling av samtliga inom kategori</h2>
            </div>
            <AppListViewFilters
              filters={filters}
              onChangeFilter={onChangeFilter}
            />
            <AppListviewDataTable
              data={users}
              refetchUsers={refetch}
              isRefetchLoading={isLoading}
              columns={defaultColumns}
              hasAccessToAssessment={currentUser?.hasAccessToPersonAssessment}
              regionId={analyticModel?.id}
            />
          </div>
          <div className="relative flex justify-center">
            <AppListviewPagination
              totalPages={totalPages}
              page={page}
              setPage={setPage}
            />
            <div className="absolute right-5 my-3">
              <UIDownload
                filename={'export.csv'}
                data={csvData}
                text="Exportera"
              />
            </div>
          </div>
        </div>
      </UIContainerCard>
    </>
  );
};

export default PageList;
