import React from 'react';
import PropTypes from 'prop-types';
import { IoArrowDownSharp } from 'react-icons/io5';

const trendOptions = [
  { id: 1, name: 'Stigande', rotation: -135 },
  { id: 2, name: 'Fallande', rotation: -45 },
  { id: 3, name: 'Oförändrad', rotation: -90 },
  { id: 4, name: 'Oförutsägbar', rotation: -90 },
];

const PersonTrend = ({ trendType }) => {
  const trend = trendOptions.find((option) => option.name === trendType);
  return (
    <div className="flex flex-col space-y-1">
      <h3 className="text-muted">Trendutveckling</h3>
      <div className="flex space-x-3 items-center">
        <div className="background-primary w-fit p-1 rounded-full">
          <IoArrowDownSharp
            className="text-counter text-2xl"
            style={{
              transform: `rotate(${trend?.rotation}deg)`,
            }}
          />
        </div>
        <span className="font-medium">{trendType}</span>
      </div>
    </div>
  );
};

PersonTrend.propTypes = {
  trendType: PropTypes.string.isRequired,
};

export default PersonTrend;
