import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { aggregationMethods, questionTypes, templateFormState } from 'state/KubenAdmin/templates';

import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

import TemplateInput from './TemplateInput';
import TemplateTextArea from './TemplateTextArea';

const TemplateQuestionPropertiesForm = ({ question, onChange }) => {
  const allAggregationMethods = useRecoilValue(aggregationMethods);
  const template = useRecoilValue(templateFormState);
  const allQuestionTypes = useRecoilValue(questionTypes);

  return (
    <div>
      <TemplateInput
        title="Id"
        name="id"
        value={question.id}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        disabled={true}
      />
      <TemplateInput
        title="Typ"
        name="type"
        value={allQuestionTypes.find((el) => el.id == question.type)?.value}
        disabled={true}
      />
      <TemplateInput
        title="Frågetext"
        name="name"
        value={question.name}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      <TemplateTextArea
        title="Beskrivning"
        name="helpText"
        value={question.helpText}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      <TemplateInput
        title="Kategori"
        name="category"
        value={question.category}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      <TemplateInput
        title="Andra hands kategori"
        name="subCategory"
        value={question.subCategory}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      <TemplateInput
        title="Icf code"
        name="icfCode"
        value={question.icfCode}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      <UIInputWrapper title="Obligatorisk">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('answerRequired', checked)}
          checked={question.answerRequired}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Beräkningsgrupp">
        <UIButtonDropdownGroup
          translate={false}
          value={template.calculationGroups.find((el) => el.id === question.calculationGroupId)?.name}
        >
          <UIItemDropdown
            value={null}
            displayValue="Välj"
            onChange={() => onChange('calculationGroupId', null)}
          />
          {template.calculationGroups.map((sel, idx) => (
            <UIItemDropdown
              value={sel.value}
              displayValue={sel.name}
              key={idx}
              onChange={() => onChange('calculationGroupId', sel.id)}
            />
          ))}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <UIInputWrapper title="Aggregeringsmethod">
        <UIButtonDropdownGroup
          translate={false}
          value={allAggregationMethods.find((sel) => sel.id === question.aggregationMethod)?.value}
        >
          {allAggregationMethods.map((sel, idx) => (
            <UIItemDropdown
              value={sel.value}
              displayValue={sel.value}
              name={'aggregationMethod'}
              key={idx}
              onChange={() => onChange('aggregationMethod', sel.id)}
            />
          ))}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <TemplateInput
        title="Synlighetskriterium"
        name="applicableCriteria"
        value={question.applicableCriteria}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      <TemplateInput
        title="Formula"
        name="pointValueFormula"
        value={question.pointValueFormula}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      <UIInputWrapper title="Ignorera i beräkningen">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('ignoreInCalculation', checked)}
          checked={question.ignoreInCalculation}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Svar kommer från externa systemet">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('answeredExternally', checked)}
          checked={question.answeredExternally}
        />
      </UIInputWrapper>
    </div>
  );
};

TemplateQuestionPropertiesForm.propTypes = {
  question: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TemplateQuestionPropertiesForm;
