import React from 'react';
import PropTypes from 'prop-types';
import { FiArrowRight } from 'react-icons/fi';
import getGraphDefinitionIcon from 'utils/KubenDashboard/getGraphDefinitionIcon';
import PersonGridCard from './PersonGridCard';

const PersonUnitsCard = ({ unitLevels }) => {
  return (
    <PersonGridCard
      title="Enheter"
      className={unitLevels.length > 2 ? 'row-span-5' : 'row-span-4'}
      icon={getGraphDefinitionIcon('NumberOfPersonsByUnit')}
      isDataBlurred={false}
    >
      <table className="w-full text-left h-fit mr-2">
        <thead>
          <tr className="text-sm font-semibold">
            <td>Område</td>
            <td>Namn</td>
            <td className="text-right">Nivå</td>
          </tr>
        </thead>
        <tbody>
          {unitLevels?.map((unit, idx) => (
            <tr
              key={idx}
              className="text-sm"
            >
              <td>{unit.businessType}</td>
              <td>{unit.unit.name}</td>
              <td className="text-right">
                {unit.levels?.length > 0 ? (
                  <div className="flex justify-end flex-row items-center space-x-1">
                    {unit.levels.map((level, index) => (
                      <div
                        key={index}
                        className="flex items-center space-x-1"
                      >
                        <span>{level}</span>
                        {index < unit.levels.length - 1 && <FiArrowRight />}
                      </div>
                    ))}
                  </div>
                ) : (
                  '-'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </PersonGridCard>
  );
};

PersonUnitsCard.propTypes = {
  unitLevels: PropTypes.array.isRequired,
};

export default PersonUnitsCard;
