import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import UITag from 'components/global/UICommon/UITag';
import _ from 'lodash';
import useFetchUserTags from 'hooks/api/user/useFetchUserTags';
import useFetchPersonTags from 'hooks/api/person/useFetchPersonTags';
import useMutateRemovePersonTag from 'hooks/api/person/useMutateRemovePersonTag';
import useMutateAddPersonTag from 'hooks/api/person/useMutateAddPersonTag';
import UISpinner from 'components/global/UICommon/UISpinner';

const PersonTags = ({ personId, region }) => {
  const {
    data: personTags,
    refetch: refetchPersonTags,
    isLoading: isFetchPersonTagsLoading,
  } = useFetchPersonTags(personId, region.id);
  const { data: userTags, refetch: refetchUserTags, isLoading: isFetchUserTagsLoading } = useFetchUserTags(region.id);
  const {
    data: removeResponse,
    mutate: mutateRemovePersonTag,
    isLoading: isRemoveTagLoading,
  } = useMutateRemovePersonTag();

  const { mutate: mutateAddTag, data: addedTags, isLoading: isAddTagLoading } = useMutateAddPersonTag();
  const [isOpen, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [text, setText] = useState('');
  const inputRef = useRef();
  const isLoading = isFetchPersonTagsLoading || isFetchUserTagsLoading || isRemoveTagLoading || isAddTagLoading;

  const setTagData = (tags) => {
    if (tags !== undefined) {
      setTags(tags);
      inputRef.current?.focus();
    }
  };

  useEffect(() => {
    if (region) {
      setTagData([]);
      refetchPersonTags(region.id);
      refetchUserTags(region.id);
    }
  }, [region.id]);

  useEffect(() => {
    setTagData(personTags);
  }, [personTags]);

  useEffect(() => {
    setTagData(removeResponse);
  }, [removeResponse]);

  useEffect(() => {
    setTagData(addedTags);
  }, [addedTags]);

  const filteredOptions = userTags?.filter(
    (tag) =>
      _.startsWith(_.toLower(tag.name), _.toLower(_.trim(text))) &&
      !tags?.some((existingTag) => existingTag.name === tag.name),
  );

  const createPayload = (tagName) => {
    const data = {
      PersonId: personId,
      Tag: {
        Name: tagName,
        RegionId: region.id,
      },
    };
    return data;
  };

  const onTagAdd = (name) => {
    if (isLoading) {
      return;
    }

    if (tags.find((t) => t.name === name.trim())) {
      toast.error('Taggen finns redan.');
    } else {
      const data = createPayload(name);
      mutateAddTag(data);
    }
  };

  const onTagRemove = (name) => {
    if (isLoading) {
      return;
    }

    const data = createPayload(name);
    mutateRemovePersonTag(data);
  };

  const handleKeyDown = (event) => {
    if (isLoading) {
      return;
    }

    if (event.code === 'Enter' && text.trim().length > 0) {
      onTagAdd(text);
      setText('');
    }
  };

  const handleBlur = () => {
    setTimeout(() => setOpen(false), 150);
  };

  return (
    <div className="relative">
      <div className="flex flex-wrap">
        {tags?.map((tag, idx) => (
          <UITag
            key={idx}
            name={tag.name}
            onClick={() => onTagRemove(tag.name)}
          />
        ))}
        <div
          className={`flex flex-col relative h-fit
             ${tags?.length == 0 || isOpen ? 'w-32' : 'w-6'}`}
          onBlur={handleBlur}
        >
          {isLoading ? (
            <div className="w-8 h-8 scale-[0.65] relative">
              <div className="absolute transform -translate-x-1/2 -translate-y-1/2 -left-1/2 -top-1/2">
                <UISpinner theme="fast" />
              </div>
            </div>
          ) : (
            <input
              ref={inputRef}
              type="text"
              onFocus={() => setOpen(true)}
              onChange={(e) => setText(e.target.value)}
              value={isOpen ? text : ''}
              placeholder={tags?.length > 0 && !isOpen ? '+' : 'Lägg till tagg'}
              onKeyDown={handleKeyDown}
              className={`px-1.5 outline-none border border-tertiary h-6 rounded
                ${tags.length === 0 ? 'w-full' : ''}
                 ${isOpen ? 'rounded-b-none w-full' : 'cursor-pointer button-item w-6'}
              `}
            />
          )}
          {isOpen && filteredOptions.length > 0 && (
            <ol
              className="w-full absolute top-full background border border-t-0 border-tertiary
             max-h-32 overflow-y-auto rounded-b
            overflow-x-hidden"
            >
              {filteredOptions.map((tag, idx) => (
                <li key={idx}>
                  <button
                    className="w-full button-item text-left px-1"
                    onClick={() => onTagAdd(tag.name, tag.regionId)}
                  >
                    {tag.name}
                  </button>
                </li>
              ))}
            </ol>
          )}
        </div>
      </div>
    </div>
  );
};

PersonTags.propTypes = {
  personId: PropTypes.string.isRequired,
  region: PropTypes.object.isRequired,
};

export default PersonTags;
