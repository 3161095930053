import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';

import { originalTemplateFormState, templateFormState, templateTabState } from 'state/KubenAdmin/templates';

import useFetchQuestionTypes from 'hooks/api/assessment/useFetchQuestionTypes';
import useFetchSingleTemplate from 'hooks/api/assessment/useFetchSingleTemplate';
import useUpdateTemplate from 'hooks/api/assessment/useUpdateTemplate';
import useFetchAggregationMethods from 'hooks/api/assessment/useFetchAggregationMethods';
import useFetchRegionList from 'hooks/api/region/useFetchRegionList';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIBackButton from 'components/global/UIButtons/UIBackButton';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';

import UIModalWithoutSaveData from 'components/KubenAdmin/UIAdmin/UIModalWithoutSaveData';
import TemplateForm from 'components/KubenAdmin/TemplatesPage/TemplateForm';
import TemplatesQuestionEditPage from './TemplatesQuestionEditPage';

const TemplatesEditPage = () => {
  const template = useRecoilValue(templateFormState);
  const originalTemplate = useRecoilValue(originalTemplateFormState);
  const tabState = useRecoilValue(templateTabState);
  const { id } = useParams();
  const history = useHistory();
  const [exitAfterSave, setExitAfterSave] = useState(false);
  const { isLoading: isQuestionTypesloading } = useFetchQuestionTypes();
  const {
    isLoading: isTemplateLoading,
    isError: isTemplateLoadingError,
    mutate: fetchTemplate,
  } = useFetchSingleTemplate();
  const {
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    error: updateError,
    mutate: mutateUpdate,
  } = useUpdateTemplate();
  const { isLoading: isAggregationMethodsLoading } = useFetchAggregationMethods();
  const { isLoading: isRegionsLoading } = useFetchRegionList();

  useEffect(() => {
    if (!id) {
      return;
    }
    if (!template) {
      fetchTemplate(id);
    }
  }, [id]);

  useEffect(() => {
    if (isUpdateSuccess && exitAfterSave) {
      history.push('/admin/templates');
    }
  }, [isUpdateSuccess]);

  const hasTemplateBeenModified = () => {
    return !_.isEqual(template, originalTemplate);
  };

  const onSave = (isExitAfterSave) => {
    mutateUpdate();
    setExitAfterSave(isExitAfterSave);
  };

  const isLoading =
    isTemplateLoading || isUpdateLoading || isAggregationMethodsLoading || isRegionsLoading || isQuestionTypesloading;
  const updateErrorResponse = isUpdateError && JSON.stringify(updateError.response?.data?.errors);

  if (isLoading) {
    return <UILoadingModal theme="assessments" />;
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UIModalWithoutSaveData
        elementToCompare={template}
        orginalElement={originalTemplate}
      />
      {!tabState.groups.editQuestionId && (
        <div className="flex w-full relative mb-5">
          <div className="absolute">
            <UIBackButton />
          </div>
          <div className="w-full justify-center text-xl font-bold mb-4">
            MALL: {template?.name}
            {hasTemplateBeenModified() && <span className="text-xl error">*</span>}
          </div>
        </div>
      )}
      {updateError && (
        <div className="border rounded flex flex-col my-5 border-red-500 text-left p-3">
          <div className="text-2xl font-semibold">{`Det gick inte att spara mallen: `}</div>
          <span className="text-rd-500">{updateErrorResponse}</span>
        </div>
      )}
      {isTemplateLoadingError && <UIErrorModal />}
      {tabState.groups.editQuestionId ? <TemplatesQuestionEditPage /> : <TemplateForm onSubmit={onSave} />}
    </UIStaticComponentsWrapper>
  );
};

export default TemplatesEditPage;
