import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { currentUserNavigationState } from 'state/global/currentUser';
import { currentSelection } from 'state/KubenAnalytics/current/currentSelection';
import { selections } from 'state/KubenAnalytics/analytics/selections';
import { analyticsTags, currentRegionModel } from 'state/KubenAnalytics/analytics/models';
import { getAnalyticsData } from 'hooks/api/analytics/useFetchAnalyticsData';
import useFetchUserTags from 'hooks/api/user/useFetchUserTags';

import UIContainerCard from 'components/global/UICommon/UIContainerCard';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';

import UIErrorModal from 'components/KubenAnalytics/UIAnalytics/UIErrorModal';
import UIAnalyticsButtonCheckbox from 'components/KubenAnalytics/UIAnalytics/UIAnalyticsButtonCheckbox';
import AppWizardProgressBar from 'components/KubenAnalytics/AppWizard/AppWizardProgressBar';
import UIBackButton from 'components/global/UIButtons/UIBackButton';

const PageWizard = () => {
  const setSelection = useSetRecoilState(currentSelection);
  const wizardSelections = useRecoilValue(selections);
  const [navigationState, setNavigationState] = useRecoilState(currentUserNavigationState);
  const [activeStep, setActiveStep] = useState(wizardSelections[0]);
  const [activeStepIdx, setActiveStepIdx] = useState(0);
  const [choices, setChoices] = useState({});
  const { isLoading: isAnalyticsDataLoading, isError: isAnalyticsDataError } = getAnalyticsData();
  const currentRegion = useRecoilValue(currentRegionModel);
  const {
    isLoading: isAnalyticsTagsLoading,
    isError: isAnalyticsTagsError,
    data: tags,
    refetch: refetchTags,
  } = useFetchUserTags(currentRegion?.id);
  const history = useHistory();
  const setTags = useSetRecoilState(analyticsTags);

  useEffect(() => {
    document.title = 'Kuben Analytics - Gör ett urval';
    refetchTags();
  }, []);

  useEffect(() => {
    if (tags) {
      setTags(tags);
    }
  }, [tags]);

  const handleOnChange = (field, value) => {
    const tempChoices = JSON.parse(JSON.stringify(choices));
    if (!tempChoices[field]) {
      tempChoices[field] = [];
    }
    if (tempChoices[field].includes(value)) {
      tempChoices[field] = tempChoices[field].filter((el) => el != value);
      setChoices(tempChoices);
      return;
    }
    if (field == 'unitIds' && isSelected(field, value)) {
      tempChoices[field] = tempChoices[field].filter((el) => el[0] != value[0]);
      setChoices(tempChoices);
      return;
    }
    tempChoices[field] = [...tempChoices[field], value];
    setChoices(tempChoices);
  };

  const isSelected = (field, value) => {
    if (!choices[field]) {
      return false;
    }
    if (field == 'unitIds') {
      return choices[field].some((unit) => unit[0] == value[0]);
    }
    return choices[field].includes(value);
  };

  useEffect(() => {
    setActiveStepIdx(wizardSelections.indexOf(activeStep));
  }, [activeStep]);

  useEffect(() => setSelection(choices), [choices]);

  const prevStep = () => {
    const prevStepIdx = activeStepIdx > 0 ? activeStepIdx - 1 : 0;
    setActiveStep(wizardSelections[prevStepIdx]);
  };

  const nextStep = () => {
    const nextStepIdx = activeStepIdx < wizardSelections.length - 1 ? activeStepIdx + 1 : activeStepIdx;
    setActiveStep(wizardSelections[nextStepIdx]);
    if (activeStepIdx === wizardSelections.length - 1) {
      setNavigationState({
        navigatePrediction: false,
      });
      if (navigationState.navigatePrediction) {
        history.push('/analytics/prediction');
      } else {
        history.push('/analytics/results');
      }
    }
  };

  const skipWizard = () => {
    setNavigationState({
      navigatePrediction: false,
    });
    if (navigationState.navigatePrediction) {
      history.push('/analytics/prediction');
    } else {
      history.push('/analytics/results');
    }
  };

  const navigateOverview = () => {
    history.push('/analytics/');
  };

  if (isAnalyticsDataLoading || isAnalyticsTagsLoading) {
    return <UILoadingModal isMessage={true} />;
  }

  if (isAnalyticsDataError || isAnalyticsTagsError) {
    return <UIErrorModal message="Hoppsan, något gick fel med urvalet." />;
  }

  return (
    <UIContainerCard>
      <div className="wizard-box h-full w-full py-10 px-14 lg:px-12 md:px-6">
        <div className="absolute top-14 left-16">
          <UIBackButton onSubmit={navigateOverview} />
        </div>
        <div className="container flex flex-col items-center justify-start mx-auto h-full">
          <div className="w-3/4 h-full flex flex-col items-center justify-start">
            <AppWizardProgressBar
              steps={wizardSelections.map((entry) => {
                if (entry.choices.length != 0) return entry.step;
              })}
              activeStepIndex={activeStepIdx}
            />
            <p className="my-10 text-2xl font-bold title">{activeStep.title}</p>
            <div className="flex flex-wrap w-9/12 md:w-full max-h-[70%] overflow-y-scroll">
              {activeStep.choices.map((choice, idx) => (
                <div
                  key={idx}
                  className="w-1/2 p-1"
                >
                  <div className="w-full text-center">
                    <UIAnalyticsButtonCheckbox
                      onChange={(value) => handleOnChange(activeStep.field, value)}
                      checked={isSelected(activeStep.field, choice.value)}
                      value={choice.value}
                      name={choice.name}
                      group={choice.name}
                      rounded="rounded-md"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className={`mt-5 w-9/12 flex items-end ${activeStepIdx != 0 ? 'justify-between' : 'justify-end'}`}>
              {activeStepIdx != 0 && (
                <button
                  onClick={prevStep}
                  className="underline pt-2 text-sm button-underlined"
                >
                  Föregående fråga
                </button>
              )}
              <div className="flex flex-col items-end self-end">
                <UIButtonSubmit
                  onSubmit={nextStep}
                  name="Nästa"
                />
                <button
                  onClick={() => skipWizard()}
                  className="underline pt-2 text-sm button-underlined"
                >
                  Hoppa över val
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UIContainerCard>
  );
};

export default PageWizard;
