import React, { useState } from 'react';
import { useRecoilState } from 'recoil';

import getUUID from 'utils/KubenAdmin/getUUID';
import { templateFormState } from 'state/KubenAdmin/templates';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIAdvancedTableElement from '../UITable/UIAdvancedTableElement';
import UIAdvancedTable from '../UITable/UIAdvancedTable';
import UIAddElementModal from '../UIAdmin/UIAddElementModal';

import TemplateCalculationGroupPropertiesForm from './TemplateCalculationGroupPropertiesForm';

const TemplateCalculationGroupList = () => {
  const [template, setTemplate] = useRecoilState(templateFormState);
  const [isAddCalculationGroup, setAddCalculationGroup] = useState(false);

  const alterCalculationGroups = (name, deleteIndex) => {
    const tmpTemplate = { ...template };
    const tmpCalculationGroups = [...tmpTemplate.calculationGroups];

    if (deleteIndex !== undefined) {
      const calculationGroupToDelete = tmpCalculationGroups[deleteIndex];

      const tmpQuestions = [...tmpTemplate.questions];
      tmpTemplate.questions.map((question, idx) => {
        if (question.calculationGroupId === calculationGroupToDelete.id) {
          const tmpQuestion = { ...question };
          tmpQuestion.calculationGroupId = null;
          tmpQuestions[idx] = tmpQuestion;
        }
      });
      tmpTemplate.questions = tmpQuestions;

      const tmpQuestionGroups = [...tmpTemplate.questionGroups];
      tmpTemplate.questionGroups.map((questionGroup, idx) => {
        if (questionGroup.calculationGroupId === calculationGroupToDelete.id) {
          const tmpQuestionGroup = { ...questionGroup };
          tmpQuestionGroup.calculationGroupId = null;
          tmpQuestionGroups[idx] = tmpQuestionGroup;
        }
      });
      tmpTemplate.questionGroups = tmpQuestionGroups;

      const tmpCalculationResults = [...tmpTemplate.calculationResults];
      tmpTemplate.calculationResults.map((calculationResult, idx) => {
        if (calculationResult.pointSource === calculationGroupToDelete.id) {
          const tmpCalculationResult = { ...calculationResult };
          tmpCalculationResult.pointSource = null;
          tmpCalculationResults[idx] = tmpCalculationResult;
        }
      });
      tmpTemplate.calculationResults = tmpCalculationResults;

      tmpCalculationGroups.splice(deleteIndex, 1);
    } else {
      tmpCalculationGroups.push({
        id: getUUID(),
        calculationGroupId: null,
        name: name,
        aggregationMethod: 'sum',
        pointMultiplier: 1.0,
      });
    }

    tmpTemplate.calculationGroups = tmpCalculationGroups;
    setTemplate(tmpTemplate);
  };

  return (
    <div>
      {isAddCalculationGroup && (
        <UIAddElementModal
          modalTitle="Lägg till beräkningsgrupp"
          inputPlaceholder="Namn"
          onSave={(text) => alterCalculationGroups(text)}
          onClose={() => setAddCalculationGroup(false)}
          isOpen={true}
        />
      )}
      <UIAdvancedTable>
        {template.calculationGroups.map((el, idx) => (
          <UIAdvancedTableElement
            key={`key_${el.id}`}
            id={`id_${el.id}`}
            index={idx}
            title={el.name}
            onDelete={() => alterCalculationGroups(null, idx)}
            canMove={false}
          >
            <TemplateCalculationGroupPropertiesForm index={idx} />
          </UIAdvancedTableElement>
        ))}
      </UIAdvancedTable>
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          name="Ny beräkningsgrupp"
          onSubmit={() => setAddCalculationGroup(true)}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
        />
      </div>
    </div>
  );
};

export default TemplateCalculationGroupList;
