import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ChooseTemplatePage from '../../../pages/KubenAssessments/ChooseTemplatePage';
import ChoosePersonPage from '../../../pages/KubenAssessments/ChoosePersonPage';
import QuestionsPage from '../../../pages/KubenAssessments/QuestionsPage';
import SummaryPage from '../../../pages/KubenAssessments/SummaryPage';
import AssessmentHistoryPage from '../../../pages/KubenAssessments/AssessmentHistoryPage';
import AssessmentsDashboardPage from '../../../pages/KubenAssessments/AssessmentsDashboardPage';
import SearchPage from '../../../pages/KubenAssessments/SearchPage';
import SearchPageResult from '../../../pages/KubenAssessments/SearchPageResult';
import AssessmentsPersonPage from 'pages/KubenAssessments/AssessmentsPersonPage';

const AppAssessmentLayout = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="h-full w-full print-this">
        <Switch>
          <Route
            exact
            path="/assessments"
            component={AssessmentsDashboardPage}
          />
          <Route
            exact
            path="/assessments/choose-person"
            component={ChoosePersonPage}
          />
          <Route
            exact
            path="/assessments/person/:personId"
            component={AssessmentsPersonPage}
          />
          <Route
            exact
            path="/assessments/choose-template"
            component={ChooseTemplatePage}
          />
          <Route
            path="/assessments/assessment-history"
            component={AssessmentHistoryPage}
          />
          <Route
            exact
            path="/assessments/questions"
            component={QuestionsPage}
          />
          <Route
            exact
            path="/assessments/questions-summary"
            component={SummaryPage}
          />
          <Route
            exact
            path="/assessments/search"
            component={SearchPage}
          />
          <Route
            exact
            path="/assessments/search/results"
            component={SearchPageResult}
          />
        </Switch>
      </div>
    </div>
  );
};

export default AppAssessmentLayout;
