import React from 'react';
import PropTypes from 'prop-types';
import { IoMdClose } from 'react-icons/io';

const UITag = ({ id, name, onClick, hover }) => {
  return (
    <div
      key={id}
      className="background border border-tertiary rounded flex items-center justify-center
        h-6 w-fit text-sm px-2 mr-2 mb-2 whitespace-nowrap"
      title={hover}
    >
      {onClick && (
        <IoMdClose
          className="text-lg cursor-pointer hover:opacity-50"
          onClick={onClick}
        />
      )}
      {name}
    </div>
  );
};

UITag.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  hover: PropTypes.string,
};

export default UITag;
