import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getInitials } from 'utils/KubenAssessments/trimUserName';

import UIBackButton from '../../global/UIButtons/UIBackButton';
import UICloseButton from '../../global/UIButtons/UICloseButton';
import UserMaleIcon from '../../icons/KubenAssessments/UserMaleIcon';
import UserFemaleIcon from '../../icons/KubenAssessments/UserFemaleIcon';
import closeElementFunction from 'utils/global/closeElementFunction';
import UIButtonLink from 'components/KubenAnalytics/UIAnalytics/UIButtonLink';

const UINameHolder = ({
  id,
  fullName,
  idNumber,
  gender = 'male',
  isButton,
  buttonFunction,
  model,
  socialSecurityNumber,
  reportId,
  customerNumber,
  isDashboardStyling = false,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const trimmedName = getInitials(fullName);
  const wrapperRef = useRef(null);
  closeElementFunction(wrapperRef, setOpen);

  return (
    <div
      ref={wrapperRef}
      className="relative name-holder"
    >
      {isButton && (
        <div className="w-[162px] mb-4">
          <UIBackButton onSubmit={buttonFunction} />
        </div>
      )}
      <div
        onClick={() => setOpen(!open)}
        className={
          `${open ? 'w-[450px] rounded-b-none border-2 shadow-xl' : 'w-fit closed border'} px-2 rounded-10px ` +
          `cursor-pointer relative flex flex-col background border-tertiary dont-print-shadow` +
          `${isDashboardStyling ? 'text-sm py-1' : 'shadow-card text-20px py-2 '}`
        }
      >
        <div className="flex flex-row items-center justify-between">
          <div className="flex items-center icon">
            {gender.toLowerCase() === 'male' ? <UserMaleIcon /> : <UserFemaleIcon />}
            <h2 className="font-semibold title mt-1">{trimmedName}</h2>
          </div>
          {open && <UICloseButton onClose={() => setOpen(!open)} />}
        </div>
      </div>
      <div
        className={
          `${!open ? 'hidden' : 'absolute w-[450px] h-30'} mt-5 flex flex-col space-y-2 background ` +
          ` py-6 left-0 border-2 shadow-xl border-t-0 border-tertiary rounded-10px rounded-t-none z-30 ` +
          `${isDashboardStyling ? 'top-2 pl-4' : 'top-6 pl-6'}`
        }
      >
        {fullName && (
          <div className="flex flex-row">
            <h2 className="text-base subtitle font-semibold mr-1">{t('UINameHolder.name')}:</h2>
            <span className="text-base text font-normal">{fullName}</span>
          </div>
        )}
        {idNumber && (
          <div className="flex flex-row">
            <h2 className="text-base subtitle font-semibold mr-1">Personnummer:</h2>
            <span className="text-base text font-normal">{idNumber}</span>
          </div>
        )}
        {socialSecurityNumber && (
          <div className="flex flex-row">
            <h2 className="text-base subtitle font-semibold mr-1">Personnummer:</h2>
            <span className="text-base text font-normal">{socialSecurityNumber}</span>
          </div>
        )}
        {customerNumber && (
          <div className="flex flex-row">
            <h2 className="text-base subtitle font-semibold mr-1">Kundnummer: </h2>
            <span className="text-base text font-normal">{customerNumber}</span>
          </div>
        )}
        {gender && (
          <div className="flex flex-row">
            <h2 className="text-base subtitle font-semibold mr-1">{t('UINameHolder.gender')}:</h2>
            <span className="text-base text font-normal">{t(`UINameHolder.${gender.toLowerCase()}`)}</span>
          </div>
        )}
        {reportId && (
          <div className="flex flex-row">
            <h2 className="text-base subtitle font-semibold mr-1">Rapport id:</h2>
            <span className="text-base text font-normal">{reportId}</span>
          </div>
        )}
        <div className="w-full flex justify-end pr-6 pt-4">
          <UIButtonLink
            link={`/assessments/person/${id}`}
            name="Gå till brukare"
            target="_blank"
          />
        </div>
      </div>
      {model != null && (
        <div className="text-left mt-3">
          <h2 className="text text-lg">
            {t('QuestionsPage.mallText')}
            {model}
          </h2>
        </div>
      )}
    </div>
  );
};

UINameHolder.propTypes = {
  id: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  isButton: PropTypes.bool,
  isDashboardStyling: PropTypes.bool,
  idNumber: PropTypes.string,
  gender: PropTypes.string,
  buttonFunction: PropTypes.func,
  model: PropTypes.string,
  socialSecurityNumber: PropTypes.string,
  reportId: PropTypes.string,
  customerNumber: PropTypes.string,
};

export default UINameHolder;
