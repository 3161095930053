import client from './clients/base';

const URL = 'assessment/';

const createTemplate = async (name) => {
  const res = await client.post(`${URL}create?name=${name}`);
  return res.data;
};

const copyTemplate = async (data) => {
  const res = await client.post(`${URL}copy`, data);
  return res.data;
};

const renameTemplate = async (data) => {
  const res = await client.post(`${URL}rename`, data);
  return res.data;
};

const updateTemplate = async (data) => {
  const res = await client.put(`${URL}save`, data);
  return res.data;
};

const deleteTemplate = async (id) => {
  const res = await client.delete(`${URL}delete/${id}`);
  return res.data;
};

const fetchSingleTemplate = async (id) => {
  const res = await client.get(`${URL}load-for-update/${id}`);
  return res.data;
};

const clearCache = async (id) => {
  const res = await client.post(`${URL}clear-cache/${id}`);
  return res.data;
};

const fetchAggregationMethods = async () => {
  const res = await client.get(`${URL}aggregation-methods`);
  return res.data;
};

const fetchQuestionTypes = async () => {
  const res = await client.get(`${URL}question-types`);
  return res.data;
};

const fetchTimePeriods = async () => {
  const res = await client.get(`${URL}time-periods`);
  return res.data;
};

const fetchHistory = async (id) => {
  const res = await client.get(`${URL}history/${id}`);
  return res.data;
};

const fetchVariableTypes = async () => {
  const res = await client.get(`${URL}variable-types/`);
  return res.data;
};

const fetchTemplateAssessment = async (templateId, regionId) => {
  const response = await client.get(`${URL}for-region/${templateId}/${regionId}`);
  return response;
};

const fetchRegionUnits = async (templateId, regionId) => {
  return await client.get(`${URL}units/${templateId}/${regionId}`);
};

const fetchRegion = async (templateId) => {
  const response = await client.get(`${URL}regions/${templateId}`);
  return response;
};

const fetchReportRegions = async (templateIds) => {
  const response = await client.post(`${URL}regions-for-assessment-templates`, templateIds);
  return response;
};

const fetchTemplates = (onlyPublished = true) => {
  return client.get(`${URL}list-items/${onlyPublished}`);
};

const fetchAllTemplatesUnits = (templateId) => {
  return client.get(`${URL}all-units-for-assessment-template/${templateId}`);
};

const fetchReportAggregationTypes = async () => {
  const response = await client.get(`${URL}aggregation-types`);
  return response.data;
};

const fetchEntityStates = async () => {
  const response = await client.get(`${URL}entity-states`);
  return response.data;
};

const actions = {
  fetchEntityStates,
  createTemplate,
  copyTemplate,
  updateTemplate,
  deleteTemplate,
  fetchSingleTemplate,
  clearCache,
  fetchAggregationMethods,
  fetchQuestionTypes,
  fetchTimePeriods,
  fetchHistory,
  fetchVariableTypes,
  fetchTemplateAssessment,
  fetchRegionUnits,
  fetchRegion,
  fetchReportRegions,
  fetchTemplates,
  fetchAllTemplatesUnits,
  fetchReportAggregationTypes,
  renameTemplate,
};

export default actions;
