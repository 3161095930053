import React from 'react';

const PersonDataCardBanner = () => {
  return (
    <div className="h-36 w-full overflow-hidden rounded-lg banner background-primary absolute">
      <div
        className="absolute background-highlight w-[15%] aspect-square
        -top-4 -left-4 3xl:-top-8 3xl:-left-8 rotate-[30deg] rounded-full"
      />
      <div
        className="absolute background-accent w-[15%] aspect-square -bottom-7 -left-1
        3xl:-bottom-14 3xl:left-2 rotate-[30deg] rounded-full"
      />
      <div
        className="absolute background-secondary w-[15%] aspect-square  top-1/4 left-14
        3xl:left-28 rotate-[60deg]"
      />
      <div
        className="absolute background-highlight w-[15%] aspect-square -top-8
        3xl:-top-16 right-1/4 rotate-[30deg] rounded-full"
      />
      <div
        className="absolute background-accent  w-[15%] aspect-square right-10
        top-1/4 3xl:right-20 rotate-[-10deg]"
      />
      <div
        className="absolute background-highlight w-[15%] aspect-square -bottom-2 -right-2
        3xl:-bottom-6 3xl:-right-6 rotate-[50deg]"
      />
    </div>
  );
};

export default PersonDataCardBanner;
