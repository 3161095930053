import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiOutlineArrowUturnRight } from 'react-icons/hi2';
import { HiOutlineClock, HiOutlineDocumentAdd } from 'react-icons/hi';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import { IoLockClosedOutline } from 'react-icons/io5';

import { currentUser, currentUserRegion } from 'state/global/currentUser';
import { chosenTemplate } from 'state/global/templates';
// eslint-disable-next-line max-len
import { assessmentHistory as assessmentHistoryRecoil } from 'state/KubenAssessments/assessmentHistory/assessmentHistory';
import { selectedPerson as selectedPersonRecoil } from 'state/KubenAssessments/saved/savedSelectedPerson';
import { chosenAssessmentAction } from 'state/KubenAssessments/chosenAssessmentAction/chosenAssessmentAction';
import { chosenRegion } from 'state/KubenAssessments/choosingTemplate/region';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';
import { regionOptions } from 'state/global/region';

import usePersonDetails from 'hooks/api/person/usePersonDetails';
import useFetchIncompleteAssessment from 'hooks/api/assessmentResult/useFetchIncompleteAssessment';
import useFetchPersonAssesmentHistory from 'hooks/api/assessmentResult/useFetchPersonAssessmentHistory';
import useFetchRegionList from 'hooks/api/region/useFetchRegionList';
import useFetchGraphPersonData from 'hooks/api/graph/useFetchGraphPersonData';
import useFetchGraphDefinitions from 'hooks/api/graph/useFetchGraphDefinitions';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import PersonDataCard from 'components/KubenAssessments/AssessmentPersonPage/PersonDataCard';

import ChooseAssessmentChoiceCard from 'components/KubenAssessments/ChooseAssessmentPage/ChooseAssessmentChoiceCard';
import PersonChartWrapper from 'components/KubenAssessments/AssessmentPersonPage/PersonChartWraper';
import PersonGridCard from 'components/KubenAssessments/AssessmentPersonPage/PersonGridCard';
import PersonUnitsCard from 'components/KubenAssessments/AssessmentPersonPage/PersonUnitsCard';
import PersonTrend from 'components/KubenAssessments/AssessmentPersonPage/PersonTrend';
import UITag from 'components/global/UICommon/UITag';
import StartIcon from 'components/icons/KubenDashboard/StartIcon';

const AssessmentsPersonPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { personId } = useParams();
  const activeRegion = useRecoilValue(currentUserRegion);
  const setRegionData = useSetRecoilState(regionOptions);

  const startDate = format(startOfMonth(subMonths(new Date(), 12)), 'yyyy-MM-dd');
  const endDate = format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd');

  // fetch user if personid is on url
  const {
    isLoading: isExistingPersonLoading,
    isError: isExistingPersonError,
    refetch: refetchExistingPerson,
  } = usePersonDetails(personId);

  const {
    isLoading: isGraphDataLoading,
    isError: isGraphDataError,
    data: graphData,
  } = useFetchGraphPersonData(personId, startDate, endDate);

  const {
    isLoading: isGraphDefinitionsLoading,
    isError: isGraphDefinitionsError,
    data: graphDefinitions,
  } = useFetchGraphDefinitions();

  const { isLoading: isRegionsLoading, isError: isRegionsError, data: regionsData } = useFetchRegionList();

  // saved data
  const selectedPerson = useRecoilValue(selectedPersonRecoil);
  const user = useRecoilValue(currentUser);
  const setChosenAssessment = useSetRecoilState(chosenAssessmentAction);
  const setChosenTemplate = useSetRecoilState(chosenTemplate);
  const setChosenRegion = useSetRecoilState(chosenRegion);
  const setAnswersAssessment = useSetRecoilState(answersAssessment);
  const assessmentHistory = useRecoilValue(assessmentHistoryRecoil);
  const hasDashboardAccess = user?.hasAccessToDashboard;

  // incomplete assessment mechanism
  const [incompleteAssessment, setIncompleteAssessment] = useState({});
  const {
    isLoading: isIncompleteAssessmentLoading,
    isError: isIncompleteAssessmentError,
    data: incompleteAssessmentData,
  } = useFetchIncompleteAssessment(selectedPerson?.id ? selectedPerson?.id : '');

  // assessment history mechanism
  const {
    isLoading: isAssessmentHistoryLoading,
    isError: isAssessmentHistoryError,
    mutate: mutateAssessmentHistoryData,
  } = useFetchPersonAssesmentHistory(
    selectedPerson?.id ? selectedPerson?.id : user?.id,
    selectedPerson?.id ? 'person' : 'user',
    9,
    1,
  );

  // refetch for assessments in analytics
  useEffect(() => {
    if (assessmentHistory?.results?.length === 0 && assessmentHistory.moreResultsAvailable) {
      mutateAssessmentHistoryData();
    }
  }, [assessmentHistory]);

  useEffect(() => {
    if (personId) {
      refetchExistingPerson();
    }
  }, [personId]);

  useEffect(() => {
    if (regionsData) {
      setRegionData(regionsData);
    }
  }, [regionsData]);

  useEffect(() => {
    mutateAssessmentHistoryData();
    if (incompleteAssessmentData != undefined) setIncompleteAssessment(incompleteAssessmentData?.data);
  }, [incompleteAssessmentData]);

  const onAssessmentCardClick = (action, url, isDisabled) => {
    if (isDisabled) return;
    if (action === 'newAssessment') {
      setChosenAssessment(action);
      history.push(url);
    }
    if (action === 'existingAssessment') {
      // user selected to modify existing assessment,
      // saving to recoil all necessary data - region, template, answersAssessment
      setChosenAssessment(action);
      setChosenRegion(incompleteAssessment?.region);
      setChosenTemplate(incompleteAssessment?.assessment);
      setAnswersAssessment(incompleteAssessment);
      history.push(url);
    }
    if (action === 'assessmentHistory') {
      history.push(url);
    }
  };

  if (
    isGraphDefinitionsLoading ||
    isGraphDataLoading ||
    isIncompleteAssessmentLoading ||
    isAssessmentHistoryLoading ||
    isExistingPersonLoading ||
    isRegionsLoading ||
    !selectedPerson.id
  ) {
    return <UILoadingModal />;
  }

  // eslint-disable-next-line max-len
  if (
    isGraphDefinitionsError ||
    isGraphDataError ||
    isIncompleteAssessmentError ||
    isAssessmentHistoryError ||
    isExistingPersonError ||
    isRegionsError
  ) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper>
      <div className="flex max-w-[1640px] mx-auto assesment-wrapper h-full space-x-4">
        <PersonDataCard
          id={selectedPerson?.id}
          fullName={selectedPerson?.name}
          customerNumber={selectedPerson?.customerNo}
          socialSecurityNumber={selectedPerson?.socialSecurityNumber}
          gender={selectedPerson?.gender}
          reportId={selectedPerson?.reportId}
          birthYear={selectedPerson?.yearOfBirth}
          tags={graphData.tags}
          region={activeRegion}
        />
        <div className="flex flex-col space-y-4 h-full">
          <div className="flex space-x-4">
            <ChooseAssessmentChoiceCard
              url="/assessments/choose-template?person=true"
              icon={<HiOutlineDocumentAdd />}
              title={t('ChooseAssessmentsPage.createNewAssessmentTitle')}
              description={t('ChooseAssessmentsPage.createNewAssessmentText')}
              isDisabled={false}
              action="newAssessment"
              handleClick={onAssessmentCardClick}
            />
            <ChooseAssessmentChoiceCard
              url="/assessments/assessment-history/person"
              icon={<HiOutlineClock />}
              title={t('ChooseAssessmentsPage.previousAssessmentsTitle')}
              description={t('ChooseAssessmentsPage.previousAssessmentsText')}
              isDisabled={assessmentHistory?.results?.length === 0 ? true : false}
              action="assessmentHistory"
              handleClick={onAssessmentCardClick}
            />
            <ChooseAssessmentChoiceCard
              url="/assessments/questions"
              icon={<HiOutlineArrowUturnRight />}
              title={t('ChooseAssessmentsPage.continuePreviousAssessmentTitle')}
              description={t('ChooseAssessmentsPage.continuePreviousAssessmentText')}
              isDisabled={incompleteAssessment == null ? true : false}
              action="existingAssessment"
              handleClick={onAssessmentCardClick}
            />
          </div>
          <div
            className="w-full flex-grow bg-white border border-tertiary rounded-2xl py-10 px-14 relative
             overflow-y-auto"
          >
            <div className="flex justify-between">
              <div className="flex flex-col text-left space-y-1">
                <div className="flex items-center space-x-2">
                  <h2 className="text-2xl font-medium text-primary">Dashboard</h2>
                  {!hasDashboardAccess && <IoLockClosedOutline className="text-xl" />}
                </div>
                <h4 className="text-sm text-muted">Visar data för de senaste 12 månaderna</h4>
              </div>
              <div>{graphData.analyticsTrend && <PersonTrend trendType={graphData.analyticsTrend} />}</div>
            </div>
            <div className="person-graph-grid pt-8">
              {graphData?.staticGraphs.map((graphData, idx) => (
                <PersonChartWrapper
                  key={idx}
                  hasAccess={hasDashboardAccess}
                  chartId={graphData.id}
                  graphDefinition={graphDefinitions.find((g) => g.graphId == graphData.id)}
                  data={graphData}
                />
              ))}
              {graphData.analyticsCategories.length > 0 && (
                <PersonGridCard
                  title="Kategorier"
                  icon={<StartIcon />}
                  className="row-span-4"
                  isDataBlurred={!hasDashboardAccess}
                >
                  {graphData.analyticsCategories.map((tag, idx) => (
                    <UITag
                      key={idx}
                      id={idx}
                      name={tag}
                    />
                  ))}
                </PersonGridCard>
              )}
              {graphData.unitLevels.length > 0 && <PersonUnitsCard unitLevels={graphData.unitLevels} />}
            </div>
          </div>
        </div>
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default AssessmentsPersonPage;
