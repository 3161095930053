import React from 'react';
import propTypes from 'prop-types';
import { useRecoilState } from 'recoil';

import { templateTabState } from 'state/KubenAdmin/templates';
import useClearCache from 'hooks/api/assessment/useClearCache';

import UILoadingModal from '../../global/UIModals/UILoadingModal';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIFoldableCard from '../UIAdmin/UIFoldableCard';

import TemplateProperties from './TemplateProperties';
import TemplateGroupList from './TemplateGroupList';
import TemplateCalculationGroupList from './TemplateCalculationGroupList';
import TemplateCalculationResultList from './TemplateCalculationResultList';
import TemplateRegionList from './TemplateRegionList';
import TemplateTables from './TemplateTables/TemplateTables';
import TemplateHistoryList from './TemplateHistoryList';
import TemplateVariablesList from './TemplateVariablesList';

const TemplateForm = ({ onSubmit }) => {
  const { isLoading: isClearCacheLoading, mutate: mutateCleraCache } = useClearCache();
  const [tabState, setTabState] = useRecoilState(templateTabState);

  if (isClearCacheLoading) {
    return <UILoadingModal />;
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col space-y-10 w-full">
        <UIFoldableCard
          title="Egenskaper"
          customOpen={tabState.properties.open}
          customSetOpen={(o) => setTabState({ ...tabState, properties: { open: o } })}
        >
          <TemplateProperties />
        </UIFoldableCard>
        <UIFoldableCard
          title="Regioner"
          customOpen={tabState.regions.open}
          customSetOpen={(o) => setTabState({ ...tabState, regions: { open: o } })}
        >
          <TemplateRegionList />
        </UIFoldableCard>
        <UIFoldableCard
          title="Grupper"
          customOpen={tabState.groups.open}
          customSetOpen={(o) => setTabState({ ...tabState, groups: { open: o } })}
        >
          <TemplateGroupList />
        </UIFoldableCard>
        <UIFoldableCard
          title="Beräkningsgrupper"
          customOpen={tabState.calculationGroups.open}
          customSetOpen={(o) => setTabState({ ...tabState, calculationGroups: { open: o } })}
        >
          <TemplateCalculationGroupList />
        </UIFoldableCard>
        <UIFoldableCard
          title="Beräkningsresultat"
          customOpen={tabState.calculationResults.open}
          customSetOpen={(o) => setTabState({ ...tabState, calculationResults: { open: o } })}
        >
          <TemplateCalculationResultList />
        </UIFoldableCard>
        <UIFoldableCard
          title="Variablar"
          customOpen={tabState.variales.open}
          customSetOpen={(o) => setTabState({ ...tabState, variales: { open: o } })}
        >
          <TemplateVariablesList />
        </UIFoldableCard>
        <UIFoldableCard
          title="Tabeller"
          customOpen={tabState.tables.open}
          customSetOpen={(o) => setTabState({ ...tabState, tables: { open: o } })}
        >
          <TemplateTables />
        </UIFoldableCard>
        <UIFoldableCard
          title="Historik"
          customOpen={tabState.history.open}
          customSetOpen={(o) => setTabState({ ...tabState, history: { open: o } })}
        >
          <TemplateHistoryList />
        </UIFoldableCard>
      </div>
      <div className="justify-center flex mt-20">
        <div className="justify-between flex space-x-8">
          <UIButtonSubmit
            name="Spara"
            onSubmit={() => onSubmit(false)}
            assessments={true}
            isIcon={false}
          />
          <UIButtonSubmit
            name="Spara och stäng"
            onSubmit={() => onSubmit(true)}
            assessments={true}
            isIcon={false}
          />
          <UIButtonSubmit
            name="Rense cache"
            onSubmit={() => mutateCleraCache()}
            assessments={true}
            isIcon={false}
          />
        </div>
      </div>
    </div>
  );
};

TemplateForm.propTypes = {
  onSubmit: propTypes.func.isRequired,
};

export default TemplateForm;
