import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useFetchAnalyticsModels from 'hooks/api/analytics/useFetchAnalyticsModels';

import { analyticsModels, currentAnalyticsModel, currentRegionModel } from 'state/KubenAnalytics/analytics/models';
import { regionOptions } from 'state/global/region';
import { currentUserRegion } from 'state/global/currentUser';

import PageWizard from './PageWizard';
import PageSavedSelection from './PageSavedSelection';
import PageSavedSelectionChart from './PageSavedSelectionChart';
import PageList from './PageList';
import PageLineChart from './PageLineChart';
import PageBarChart from './PageBarChart';
import UISpinner from 'components/global/UICommon/UISpinner';

const PageAnalyticsWrapper = () => {
  const location = useLocation();
  const {
    isLoading: isGetAnalyticsModelsLoading,
    isError: isGetAnalyticsModelsError,
    data,
  } = useFetchAnalyticsModels();
  const analyticModels = useRecoilValue(analyticsModels);
  const setCurrentRegionModel = useSetRecoilState(currentRegionModel);
  const setCurrentAnalyticModel = useSetRecoilState(currentAnalyticsModel);
  const setOptions = useSetRecoilState(regionOptions);
  const userRegion = useRecoilValue(currentUserRegion);

  useEffect(() => {
    if (userRegion) {
      setCurrentRegionModel(userRegion.region);
      setCurrentAnalyticModel(userRegion.model);
    }
  }, [userRegion]);

  useEffect(() => {
    if (data) {
      const options = data.flatMap((model) =>
        model?.regions.map((region) => ({
          id: region.id,
          name: region.name,
          region: region,
          model: model,
        })),
      );
      setOptions(options);
    }
  }, [data]);

  const renderApp = () => {
    if (location.pathname === '/analytics/filter') {
      return <PageWizard />;
    }
    if (location.pathname === '/analytics/saved-selection') {
      return <PageSavedSelection />;
    }
    if (location.pathname === '/analytics/effects') {
      return <PageSavedSelectionChart />;
    }
    if (location.pathname === '/analytics/results') {
      return <PageBarChart />;
    }
    if (location.pathname === '/analytics/prediction') {
      return <PageLineChart />;
    }
    if (location.pathname === '/analytics/list') {
      return <PageList />;
    }
  };

  if (isGetAnalyticsModelsLoading || analyticModels.length === 0) {
    return <UISpinner theme="fast" />;
  }

  if (isGetAnalyticsModelsError) {
    return <div>Error occurred</div>;
  }

  return renderApp();
};

export default PageAnalyticsWrapper;
