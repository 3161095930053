import { atom } from 'recoil';

export const IsOverlayActive = atom({
  key: 'isOverlayActive',
  default: false,
});

export const isConfettiActive = atom({
  key: 'isConfettiActive',
  default: false,
});
