import React, { useRef, useState } from 'react';
import propTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';

import actions from 'api/auth';
import closeElementFunction from 'utils/global/closeElementFunction';
import getAppSwitcherItems from 'utils/KubenDashboard/getAppSwitcherItems';
import { currentUser } from 'state/global/currentUser';

import useFetchRegionList from 'hooks/api/region/useFetchRegionList';

import AppSwitcherButton from 'components/icons/global/AppSwitcherButton';
import AppSwitcher from 'components/KubenDashboard/TopPanel/AppSwitcher/AppSwitcher';
import RegionSelector from './RegionSelector';

const SideBarTopPanel = ({ currentApp }) => {
  const history = useHistory();
  const wrapperRef = useRef(null);
  const [isAppSwitcherVisible, setIsAppSwitcherVisible] = useState(false);
  const user = useRecoilValue(currentUser);
  const appsData = getAppSwitcherItems(user);
  const { data: options } = useFetchRegionList();

  closeElementFunction(wrapperRef, setIsAppSwitcherVisible);

  const handleAppSwitcherClick = (appName, title) => {
    if (appName === 'logout') {
      actions.logout();
    } else {
      const url = appName === 'admin' && !user?.hasAccessToAdminUser ? `/${appName}/regions` : `/${appName}`;
      document.title = title;
      history.push(url);
      setIsAppSwitcherVisible(false);
    }
  };

  return (
    <div
      className="w-full flex min-h-[48px] items-center relative justify-end navigation
    transition-colors duration-200 ease-in-out dont-print-this"
    >
      <div className="flex h-7 items-center w-fit justify-end pr-14 space-x-4">
        <RegionSelector options={options} />
        <button
          className="rounded-md p-[7px] app-switcher z-30"
          onClick={() => setIsAppSwitcherVisible(!isAppSwitcherVisible)}
        >
          <AppSwitcherButton />
        </button>
        {isAppSwitcherVisible && (
          <div
            ref={wrapperRef}
            className="absolute top-14 right-14 z-50"
          >
            <AppSwitcher
              items={appsData}
              currentApp={currentApp}
              onClick={handleAppSwitcherClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

SideBarTopPanel.propTypes = {
  currentApp: propTypes.string.isRequired,
};

export default SideBarTopPanel;
