import query from '../useQueryWrapper';
import actions from 'api/person';

const useFetchPersonTags = (personId, regionId) => {
  return query.useQueryWrapper(
    'useFetchPersonTags',
    async () => {
      const res = await actions.fetchPersonTags(personId, regionId);
      return res.data;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchPersonTags;
