import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useFetchGraphDefinitions from 'hooks/api/graph/useFetchGraphDefinitions';
import useFetchGraphDefinitionCategories from 'hooks/api/graph/useFetchGraphDefinitionCategories';
import useFetchGraphRegions from 'hooks/api/graph/useFetchGraphRegions';

import { graphDrillDownData } from 'state/KubenDashboard/graphs';
import { isDashboardScroll } from 'state/KubenDashboard/filter';
import { regionOptions } from 'state/global/region';
import { currentUserRegion } from 'state/global/currentUser';

import DashboardPage from 'pages/KubenDashboard/DashboardPage';
import GraphDetailPage from 'pages/KubenDashboard/GraphDetailPage';
import DashboardChartInformationModal from 'components/KubenDashboard/Charts/DashboardChartInformationModal';
import DashboardFilterPanel from 'components/KubenDashboard/DashboardFilterPanel';
import UIErrorModal from '../UIModals/UIErrorModal';
import UILoadingModal from '../UIModals/UILoadingModal';

const AppDashboardLayout = () => {
  const setScroll = useSetRecoilState(isDashboardScroll);
  const drillDownData = useRecoilValue(graphDrillDownData);
  const chosenGraphRegion = useRecoilValue(currentUserRegion);
  const setRegionOptions = useSetRecoilState(regionOptions);
  const history = useHistory();
  const dashboardWrapperRef = useRef();
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const { isLoading: isGraphDefinitionsLoading, isError: isGraphDefinitionsError } = useFetchGraphDefinitions();
  const {
    isLoading: isGraphDefinitionCategoriesLoading,
    isError: isGraphDefinitionCategoriesError,
    refetch: reFetchGraphDefinitionCategories,
  } = useFetchGraphDefinitionCategories();
  const {
    isLoading: isGraphRegionsLoading,
    isSuccess: isGraphRegionsSuccess,
    data: graphRegions,
  } = useFetchGraphRegions();
  // eslint-disable-next-line no-unused-vars
  const isLoading = isGraphDefinitionsLoading || isGraphDefinitionCategoriesLoading || isGraphRegionsLoading;
  const isError = isGraphDefinitionsError || isGraphDefinitionCategoriesError;

  useEffect(() => {
    reFetchGraphDefinitionCategories();
  }, []);

  useEffect(() => {
    if (isGraphRegionsSuccess) {
      setRegionOptions(graphRegions);
    }
  }, [isGraphRegionsSuccess]);

  const onScroll = (e) => {
    const scrollableDiv = e.target;
    const scrollTop = scrollableDiv.scrollTop;
    const scrollHeight = scrollableDiv.scrollHeight - scrollableDiv.clientHeight;
    const perc = scrollTop / scrollHeight;
    setScroll(perc > 0.0);
  };

  useEffect(() => {
    document.getElementById('content-wrapper').addEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (drillDownData.graphId) {
      history.push(
        // eslint-disable-next-line max-len
        `/dashboard/${drillDownData.graphId}?drillDownDataKey=${drillDownData.data}&drillDownTitleKey=${drillDownData.title}`,
      );
    }
  }, [drillDownData, history]);

  useEffect(() => {
    const handleResize = (entries) => {
      for (const entry of entries) {
        setWrapperWidth(entry.contentRect.width);
      }
    };

    const observer = new ResizeObserver(handleResize);
    if (dashboardWrapperRef.current) {
      observer.observe(dashboardWrapperRef.current);
    }

    return () => {
      if (observer && dashboardWrapperRef.current) {
        observer.unobserve(dashboardWrapperRef.current);
      }
      observer.disconnect();
    };
  }, [dashboardWrapperRef]);

  return (
    <div
      className="h-full w-full transition-all ease"
      ref={dashboardWrapperRef}
    >
      {isError ? (
        <UIErrorModal />
      ) : chosenGraphRegion == null ? (
        <UILoadingModal />
      ) : (
        <DashboardFilterPanel
          width={wrapperWidth}
          chosenGraphRegion={chosenGraphRegion}
        />
      )}
      <Switch>
        <Route
          exact
          path="/dashboard"
          component={DashboardPage}
        />
        <Route
          exact
          path="/dashboard/:chartId"
          component={GraphDetailPage}
        />
      </Switch>
      <DashboardChartInformationModal />
    </div>
  );
};

export default AppDashboardLayout;
