import React, { useState } from 'react';
import { useRecoilState } from 'recoil';

import getUUID from 'utils/KubenAdmin/getUUID';
import { templateFormState, templateTabState } from 'state/KubenAdmin/templates';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIAdvancedTableElement from '../UITable/UIAdvancedTableElement';
import UIAdvancedTable from '../UITable/UIAdvancedTable';
import UIAddElementModal from '../UIAdmin/UIAddElementModal';

import TemplateGroupPropertiesForm from './TemplateGroupPropertiesForm';
import TemplateQuestionListModal from './TemplateQuestionListModal';

const TemplateGroupList = () => {
  const [isAddGroup, setAddGroup] = useState(false);
  const [template, setTemplate] = useRecoilState(templateFormState);
  const [tabState, setTabState] = useRecoilState(templateTabState);

  const alterGroups = (name, deleteIndex, reorderedGroups) => {
    const tmpTemplate = { ...template };

    if (reorderedGroups !== undefined) {
      tmpTemplate.questionGroups = reorderedGroups;
    } else {
      const tmpGroups = [...tmpTemplate.questionGroups];
      if (deleteIndex !== undefined) {
        const groupToDelete = tmpGroups[deleteIndex];
        tmpTemplate.questions = template.questions.filter((q) => q.groupId !== groupToDelete.id);
        tmpGroups.splice(deleteIndex, 1);
      } else {
        tmpGroups.push({
          id: getUUID(),
          name: name,
          description: null,
          category: null,
          aggregationMethod: 'sum',
          isQualityGroup: true,
          calculationGroupId: null,
        });
      }
      tmpTemplate.questionGroups = tmpGroups;
    }
    setTemplate(tmpTemplate);
  };

  return (
    <div>
      {isAddGroup && (
        <UIAddElementModal
          modalTitle="Lägg till grupp"
          inputPlaceholder="Namn"
          onSave={(text) => alterGroups(text)}
          onClose={() => setAddGroup(false)}
          isOpen={true}
        />
      )}
      <UIAdvancedTable
        list={template.questionGroups}
        onReorder={(orderedList) => alterGroups(null, undefined, orderedList)}
      >
        {template.questionGroups.map((el, idx) => (
          <UIAdvancedTableElement
            key={`key_${el.id}`}
            id={`id_${el.id}`}
            index={idx}
            title={el.name}
            onDelete={() => alterGroups(null, idx)}
          >
            <TemplateGroupPropertiesForm index={idx} />
          </UIAdvancedTableElement>
        ))}
      </UIAdvancedTable>
      {tabState.groups.groupIdWithOpenQuestions && (
        <TemplateQuestionListModal
          group={template.questionGroups.find((grp) => grp.id === tabState.groups.groupIdWithOpenQuestions)}
          onClose={() => setTabState({ ...tabState, groups: { ...tabState.groups, groupIdWithOpenQuestions: null } })}
        />
      )}
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          name="Ny grupp"
          onSubmit={() => setAddGroup(true)}
          assessments={true}
          isIcon={false}
        />
      </div>
    </div>
  );
};

export default TemplateGroupList;
