import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';

const PersonDataRowToggleValue = ({ value, toggleValue, className }) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <div className="relative w-fit">
      <span className={className}>{isVisible ? toggleValue : value}</span>
      <button
        className="border border-tertiary px-2 rounded-md button-item
         absolute -right-12 transform -translate-y-1/2 top-1/2"
        onClick={() => setVisible(!isVisible)}
        title={`${isVisible ? 'Dölj' : 'Visa'}`}
      >
        {isVisible ? (
          <IoEyeOutline className="text-lg text-primary" />
        ) : (
          <IoEyeOffOutline className="text-lg text-primary" />
        )}
      </button>
    </div>
  );
};

PersonDataRowToggleValue.propTypes = {
  value: PropTypes.string.isRequired,
  toggleValue: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PersonDataRowToggleValue;
