import actions from 'api/graph';
import query from '../useQueryWrapper';

const useFetchGraphPersonData = (personId, startDate, endDate) => {
  return query.useQueryWrapper(
    'useFetchGraphPersonData',
    async () => {
      const res = await actions.fetchGraphPersonData(personId, startDate, endDate);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchGraphPersonData;
