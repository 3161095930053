import useFetchRegionList from 'hooks/api/region/useFetchRegionList';
import useMutateSetUserDefaultRegion from 'hooks/api/user/useMutateSetUserDefaultRegion';
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentUser, currentUserRegion } from 'state/global/currentUser';
import { regionOptions } from 'state/global/region';

const RegionSelector = () => {
  const [search, setSearch] = useState('');
  const [isOpen, setOpen] = useState(false);
  const { data: regionList } = useFetchRegionList();
  const { mutate: mutateSetRegion } = useMutateSetUserDefaultRegion();
  const [region, setRegion] = useRecoilState(currentUserRegion);
  const [regions, setRegions] = useRecoilState(regionOptions);
  const user = useRecoilValue(currentUser);
  const ref = useRef();

  const filteredOptions = regions?.filter((region) =>
    region.name.toLowerCase().startsWith(search.toLowerCase().trim()),
  );

  useEffect(() => {
    if (regionList) {
      setRegions(regionList);
    }
  }, [regionList, setRegions]);

  const handleRegionSelect = (region) => {
    setRegion(region);
    setOpen(false);
    setSearch('');
    mutateSetRegion({ regionId: region.id });
    ref.current?.blur();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && filteredOptions.length > 0) {
      handleRegionSelect(filteredOptions[0]);
    }
  };

  const handleBlur = () => {
    setTimeout(() => setOpen(false), 150);
  };

  useEffect(() => {
    let defaultRegion;

    if (regions && (!region || !regions.some((r) => r.id === region.id))) {
      if (user?.defaultRegionId) {
        defaultRegion = regions.find((r) => r.id === user.defaultRegionId);
      }

      if (!defaultRegion) {
        defaultRegion = regions[0];
      }

      setRegion(defaultRegion);
    }
  }, [regions, region, user?.defaultRegionId, setRegion]);

  if (!regions) return null;

  return (
    <div
      className="flex flex-col relative h-8 z-20"
      ref={ref}
      onBlur={handleBlur}
      onClick={() => setOpen(true)}
    >
      <input
        type="text"
        readOnly={!isOpen}
        onChange={(e) => setSearch(e.target.value)}
        value={isOpen ? search : region?.name || 'Välj region'}
        placeholder="Välj region"
        onKeyDown={handleKeyDown}
        aria-expanded={isOpen}
        aria-controls="region-list"
        className={`px-1.5 outline-none border h-full text-primary text-sm rounded-md pr-4 ${
          isOpen ? 'rounded-b-none' : 'cursor-pointer button-item placeholder-gray-500'
        }`}
      />
      <div className="absolute right-2 transform -translate-y-1/2 top-1/2 pointer-events-none text-primary">
        {isOpen ? <FiChevronUp className="w-full h-full" /> : <FiChevronDown className="w-full h-full" />}
      </div>
      {isOpen && filteredOptions.length > 0 && (
        <div
          id="region-list"
          className="w-full absolute top-full background border border-t-0 max-h-48 overflow-y-auto
           drop-shadow-xl rounded-b-md flex flex-col"
        >
          {filteredOptions.map((region, idx) => (
            <button
              key={region.id}
              className={`w-full button-item text-sm text-primary text-left px-1 py-0.5 ${
                idx === 0 && search.length > 0 ? 'background-secondary' : ''
              }`}
              onClick={() => handleRegionSelect(region)}
            >
              {region.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default RegionSelector;
