import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { searchQuery } from 'state/global/searchQuery';
import { chosenTemplate, templatesFiltered, templates } from 'state/global/templates';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';
import { templateAssessment } from 'state/KubenAssessments/assessments/templateAssessment';
import { chosenRegion, region, regionFiltered } from 'state/KubenAssessments/choosingTemplate/region';
import { selectedPerson as selectedPersonRecoil } from 'state/KubenAssessments/saved/savedSelectedPerson';
import { sortType } from 'state/KubenAssessments/choosingTemplate/sortType';
import { currentQuestionState } from 'state/KubenAssessments/questions/currentQuestions';
import { chosenAssessmentAction } from 'state/KubenAssessments/chosenAssessmentAction/chosenAssessmentAction';

import useFetchTemplates from 'hooks/api/assessment/useFetchTemplates';
import useFetchRegion from 'hooks/api/assessment/useFetchRegion';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UINameHolder from 'components/KubenAssessments/UIAssessments/UINameHolder';
import ChooseTemplateTable from 'components/KubenAssessments/ChooseTemplatePage/ChooseTemplateTable';
import ChooseTemplateHeader from 'components/KubenAssessments/ChooseTemplatePage/ChooseTemplateHeader';

const ChooseTemplatePage = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const hasPerson = searchParams.get('person');
  const history = useHistory();
  const selectedPerson = useRecoilValue(selectedPersonRecoil);

  // templates mechanism
  const { isLoading: isTemplatesLoading, isError: isTemplatesError, refetch: refetchTemplates } = useFetchTemplates();
  const templatesData = useRecoilValue(templatesFiltered);
  const allTemplates = useRecoilValue(templates);
  const [chosenTemplateData, setChosenTemplateData] = useRecoilState(chosenTemplate);
  const resetChosenTemplateData = useResetRecoilState(chosenTemplate);
  const resetAnswersAssessmentData = useResetRecoilState(answersAssessment);
  const resetTemplateAssessmentData = useResetRecoilState(templateAssessment);
  const resetSelectedPerson = useResetRecoilState(selectedPersonRecoil);
  const resetChosenAssessmentAction = useResetRecoilState(chosenAssessmentAction);

  // regions mechanism
  const {
    isLoading: isRegionLoading,
    isError: isRegionError,
    refetch: refetchRegion,
  } = useFetchRegion(chosenTemplateData.id);
  const [isInitFetch, setIsInitFetch] = useState(true);
  const regionData = useRecoilValue(regionFiltered);
  const resetRegionData = useResetRecoilState(region);
  const setChosenRegionTemplateData = useSetRecoilState(chosenRegion);
  const resetChosenRegionData = useResetRecoilState(chosenRegion);
  const resetCurrentQuestion = useResetRecoilState(currentQuestionState);

  // search mechanism
  const [searchText, setSearchText] = useRecoilState(searchQuery);
  const resetSearchData = useResetRecoilState(searchQuery);

  // sort mechanism
  const [sorting, setSorting] = useRecoilState(sortType);
  const resetSorting = useResetRecoilState(sortType);

  useEffect(() => {
    if (!hasPerson) {
      resetSelectedPerson();
      resetChosenAssessmentAction();
    }
    refetchTemplates();
    resetChosenTemplateData();
    resetChosenRegionData();
    resetAnswersAssessmentData();
    resetTemplateAssessmentData();
    resetCurrentQuestion();
  }, []);

  useEffect(() => {
    // go direct to questions page in case of only one region
    if (regionData?.length === 1) {
      setChosenRegionTemplateData(regionData[0]);
      resetRegionData();
      history.push('/assessments/questions');
    }
  }, [regionData]);

  useEffect(() => {
    if (Object.keys(chosenTemplateData).length !== 0 && !isInitFetch) {
      refetchRegion();
    }
    setIsInitFetch(false);
  }, [chosenTemplateData]);

  const handleTemplateChoose = (element) => {
    resetSearchData();
    resetSorting();
    setChosenTemplateData(element);
  };

  const handleRegionChoose = (element) => {
    setChosenRegionTemplateData(element);
    history.push('/assessments/questions');
  };

  const handleSelectSort = (value) => {
    setSorting(value);
  };

  const onBackButtonClick = () => {
    if (Object.keys(chosenTemplateData).length !== 0) {
      resetSearchData();
      resetSorting();
      resetChosenTemplateData();
    } else {
      history.goBack();
    }
  };

  if (isTemplatesLoading || isRegionLoading || allTemplates.length == 0) {
    return <UILoadingModal />;
  }

  if (isTemplatesError || isRegionError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper
      extraSpace={true}
      isTopMargin={false}
    >
      <div className="flex flex-col w-full pb-20  md:ml-12">
        {Object.keys(selectedPerson).length !== 0 && (
          <UINameHolder
            id={selectedPerson?.id}
            fullName={selectedPerson?.name}
            idNumber={selectedPerson?.personId}
            socialSecurityNumber={selectedPerson?.socialSecurityNumber}
            customerNumber={selectedPerson?.customerNo}
            gender={selectedPerson?.gender}
            reportId={selectedPerson?.reportId}
            isButton={false}
          />
        )}
        <div className="my-6">
          <ChooseTemplateHeader
            step={Object.keys(chosenTemplateData).length === 0 ? '1' : '2'}
            totalSteps="2"
            sortType={sorting}
            onChange={handleSelectSort}
            chosenTemplate={chosenTemplateData.name}
            buttonFunction={onBackButtonClick}
          />
        </div>
        {Object.keys(chosenTemplateData).length === 0 ? (
          <ChooseTemplateTable
            header={t('ChooseTemplatePage.templateTitle')}
            handleChange={handleTemplateChoose}
            data={templatesData}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        ) : (
          <ChooseTemplateTable
            header={t('ChooseTemplatePage.regionTitle')}
            isRegion={true}
            handleChange={handleRegionChoose}
            data={regionData}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        )}
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default ChooseTemplatePage;
