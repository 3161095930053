import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoCloseSharp } from 'react-icons/io5';
import { useRecoilValue } from 'recoil';

import { currentRegionModel } from 'state/KubenAnalytics/analytics/models';

import UIModalWrapper from 'components/global/UIModals/UIModalWrapper';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';

const AppListViewDataTagsModal = ({ onClose, selectedPerson, regionId, onTagAdd, onTagRemove }) => {
  const region = useRecoilValue(currentRegionModel);
  const [inputText, setInputText] = useState();
  const [tags, setTags] = useState(selectedPerson.tags);

  const getBody = (tagName) => {
    return {
      PersonId: selectedPerson.id,
      Tag: {
        Name: tagName,
        RegionId: regionId,
      },
    };
  };

  const onAddTag = (tag) => {
    if (!tag) {
      return;
    }

    setTags([...tags, { name: tag, regionId: region.id }]);
    const body = getBody(tag);
    onTagAdd(body);
    setInputText('');
  };

  const onDeleteTag = (tag) => {
    const newTagsArray = tags.filter((el) => el.name != tag.name);
    setTags(newTagsArray);
    const body = getBody(tag.name);
    onTagRemove(body);
  };

  return (
    <UIModalWrapper
      isOpen={true}
      onClose={onClose}
      variant="center"
      isCloseButton={true}
      lessRounded={true}
      width="w-1/2"
      height="h-1/2"
    >
      <div className="h-full flex flex-col justify-between">
        <div>
          <h2 className="p-4 text-left font-semibold border-b border-gray-300">Taggar</h2>
          <div className="p-4">
            <input
              className="w-full border border-counter rounded-md p-2"
              placeholder="Lägg till ny tagg"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
            />
            <div className="flex justify-end p-4">
              <button
                className="flex items-center underline"
                onClick={() => onAddTag(inputText)}
              >
                Lägg till
              </button>
            </div>
          </div>
          {tags.length > 0 && (
            <div className="flex flex-wrap pt-3 pl-2">
              {tags.map((el, idx) => (
                <div
                  key={idx}
                  className="border ml-2 mb-1 border-gray-300 px-3 py-1 text-xs shadow flex items-center rounded-full"
                >
                  {el.name}
                  <IoCloseSharp
                    className="ml-2 text-xl cursor-pointer"
                    onClick={() => onDeleteTag(el)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex items-center justify-end space-x-4 p-4 border-t border-gray-300">
          <UIButtonSubmit
            variant="background-primary"
            name="Stäng"
            onSubmit={onClose}
          />
        </div>
      </div>
    </UIModalWrapper>
  );
};

AppListViewDataTagsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedPerson: PropTypes.object.isRequired,
  regionId: PropTypes.string.isRequired,
  onTagAdd: PropTypes.func.isRequired,
  onTagRemove: PropTypes.func.isRequired,
};

export default AppListViewDataTagsModal;
