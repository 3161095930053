import mutation from '../useQueryWrapper';
import actions from 'api/user';

const useMutateSetUserDefaultRegion = () => {
  return mutation.useMutationWrapper(
    async (regionId) => {
      const data = await actions.setUserDefaultRegion(regionId);
      return data;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useMutateSetUserDefaultRegion;
