import React from 'react';
import propTypes from 'prop-types';
import formatNumber from 'utils/KubenDashboard/formatNumber';

const DashboardPreviewChartToolTip = ({ isActive, payload }) => {
  return (
    <div>
      <div>
        {payload.map((data, idx) => {
          return (
            <div
              key={idx}
              style={{
                color: data.fill,
                opacity: '.7',
              }}
            >
              <div className="font-medium text-sm text-primary">
                <span className="text-primary mr-1 font-medium text-xs">{data.payload.name}</span>
                {formatNumber(data.payload[data.dataKey])}
                <span className="text-xs ml-0.5">{data.unit}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

DashboardPreviewChartToolTip.propTypes = {
  isActive: propTypes.bool,
  payload: propTypes.any,
};

export default DashboardPreviewChartToolTip;
