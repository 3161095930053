import actions from 'api/user';
import query from '../useQueryWrapper';

const useFetchUserTags = (regionId) => {
  return query.useQueryWrapper(
    'useFetchUserTags',
    async () => {
      const tags = await actions.fetchUserTags(regionId);
      return tags;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchUserTags;
