/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UIButtonCheckbox from 'components/global/UIButtons/UIButtonCheckbox';
import QuestionTimeSelect from '../QuestionTimeSelect';

const QuestionWeeklyScheduleType = ({
  userAnswers,
  handleClick,
  questionId,
  isReadOnly,
  isAnsweredExternally,
  isSummaryView = false,
}) => {
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutes = Array.from({ length: 4 }, (_, i) => i * 15);

  const filteredStartTimeHours = (endTime) => {
    if (endTime.hour != null && endTime.hour != '00') {
      return hours.filter((hour) => hour <= endTime.hour);
    }
    return hours;
  };
  const filteredStartTimeMinutes = (endTime, selectedHour) => {
    if (selectedHour != null && selectedHour === endTime.hour && endTime.hour != '00') {
      return minutes.filter((minute) => minute < endTime.minute);
    }
    return minutes;
  };
  const filteredEndTimeHours = (startTime) => hours.filter((hour) => hour >= startTime.hour);
  const filteredEndTimeMinutes = (startTime, selectedHour) => {
    if (selectedHour === startTime.hour) {
      return minutes.filter((minute) => minute > startTime.minute);
    }
    return minutes;
  };

  const [schedule, setSchedule] = useState([
    {
      title: 'Måndag',
      id: 'Monday',
      dayOfWeek: 1,
      startTime: {
        hour: null,
        minute: null,
      },
      endTime: { hour: null, minute: null },
      isActive: false,
    },
    {
      title: 'Tisdag',
      id: 'Tuesday',
      dayOfWeek: 2,
      startTime: { hour: null, minute: null },
      endTime: { hour: null, minute: null },
      isActive: false,
    },
    {
      title: 'Onsdag',
      id: 'Wednesday',
      dayOfWeek: 3,
      startTime: { hour: null, minute: null },
      endTime: { hour: null, minute: null },
      isActive: false,
    },
    {
      title: 'Torsdag',
      id: 'Thursday',
      dayOfWeek: 4,
      startTime: { hour: null, minute: null },
      endTime: { hour: null, minute: null },
      isActive: false,
    },
    {
      title: 'Fredag',
      id: 'Friday',
      dayOfWeek: 5,
      startTime: { hour: null, minute: null },
      endTime: { hour: null, minute: null },
      isActive: false,
    },
  ]);

  const handleCheck = (dayOfWeek) => {
    setSchedule((prevSchedule) => {
      const newSchedule = prevSchedule.map((day) =>
        day.dayOfWeek === dayOfWeek ? { ...day, isActive: !day.isActive } : day,
      );

      const processedSchedule = newSchedule
        .filter((item) => item.isActive)
        .map((item) => {
          const formatTime = (time) => {
            const hour = time.hour !== null ? time.hour.toString().padStart(2, '0') : '00';
            const minute = time.minute !== null ? time.minute.toString().padStart(2, '0') : '00';
            return `${hour}:${minute}:00`;
          };

          return {
            dayOfWeek: item.dayOfWeek,
            startTime: formatTime(item.startTime),
            endTime: formatTime(item.endTime),
          };
        });

      handleClick(questionId, 'WeeklySchedule', processedSchedule);

      return newSchedule;
    });
  };

  const handleTimeChange = (dayOfWeek, field, type, value) => {
    setSchedule((prevSchedule) => {
      const newSchedule = prevSchedule.map((day) =>
        day.dayOfWeek === dayOfWeek ? { ...day, [field]: { ...day[field], [type]: value } } : day,
      );

      const processedSchedule = newSchedule
        .filter((item) => item.isActive)
        .map((item) => {
          const formatTime = (time) => {
            const hour = time.hour !== null ? time.hour.toString().padStart(2, '0') : '00';
            const minute = time.minute !== null ? time.minute.toString().padStart(2, '0') : '00';
            return `${hour}:${minute}:00`;
          };

          return {
            dayOfWeek: item.dayOfWeek,
            startTime: formatTime(item.startTime),
            endTime: formatTime(item.endTime),
          };
        });

      handleClick(questionId, 'WeeklySchedule', processedSchedule);

      return newSchedule;
    });
  };

  const mapUserAnswersToSchedule = (userAnswers, schedule) => {
    return schedule.map((day) => {
      const answer = userAnswers.find((answer) => answer.dayOfWeek === day.id);
      if (answer) {
        const [startHour, startMinute] = answer.startTime.split(':').map(Number);
        const [endHour, endMinute] = answer.endTime.split(':').map(Number);
        return {
          ...day,
          startTime: { hour: startHour, minute: startMinute },
          endTime: { hour: endHour, minute: endMinute },
          isActive: true,
        };
      }
      return day;
    });
  };

  useEffect(() => {
    if (userAnswers?.dailySchedules?.length > 0) {
      const updatedSchedule = mapUserAnswersToSchedule(userAnswers.dailySchedules, schedule);
      setSchedule(updatedSchedule);
    }
  }, [userAnswers]);

  return (
    <div className="flex flex-col space-y-8 w-full">
      {schedule.map((el) => (
        <div
          key={el.dayOfWeek}
          className="flex items-center justify-between font-medium relative"
        >
          <div className="flex items-center w-[30%]">
            <UIButtonCheckbox
              checked={el.isActive}
              setChecked={() => handleCheck(el.dayOfWeek)}
              isDisabledPermissions={isReadOnly}
            />
            <div className={`text-lg ${el.isActive && !isReadOnly ? '' : 'opacity-60'}`}>{el.title}</div>
          </div>
          <div className="flex items-center space-x-2 w-[35%]">
            <p className={`text-sm ${el.isActive && !isReadOnly ? '' : 'opacity-60'}`}>Påbörjas</p>
            <div className="flex items-center space-x-1">
              <QuestionTimeSelect
                value={el.startTime.hour}
                data={filteredStartTimeHours(el.endTime)}
                isReadOnly={isReadOnly}
                isActive={el.isActive}
                onChange={(value) => handleTimeChange(el.dayOfWeek, 'startTime', 'hour', value)}
                isAnsweredExternally={isAnsweredExternally}
              />
              <span>:</span>
              <QuestionTimeSelect
                value={el.startTime.minute}
                data={filteredStartTimeMinutes(el.endTime, el.startTime.hour)}
                isReadOnly={isReadOnly}
                isActive={el.isActive}
                onChange={(value) => handleTimeChange(el.dayOfWeek, 'startTime', 'minute', value)}
                isAnsweredExternally={isAnsweredExternally}
              />
            </div>
          </div>
          <div className={`flex items-center space-x-2 w-[35%] ${isSummaryView ? 'justify-center' : 'justify-end'}`}>
            <p className={`text-sm ${el.isActive && !isReadOnly ? '' : 'opacity-60'}`}>Avslutas</p>
            <div className="flex items-center space-x-1">
              <QuestionTimeSelect
                value={el.endTime.hour}
                data={filteredEndTimeHours(el.startTime)}
                isReadOnly={isReadOnly}
                isActive={el.isActive}
                onChange={(value) => handleTimeChange(el.dayOfWeek, 'endTime', 'hour', value)}
                isAnsweredExternally={isAnsweredExternally}
              />
              <span>:</span>
              <QuestionTimeSelect
                value={el.endTime.minute}
                data={filteredEndTimeMinutes(el.startTime, el.endTime.hour)}
                isReadOnly={isReadOnly}
                isActive={el.isActive}
                onChange={(value) => handleTimeChange(el.dayOfWeek, 'endTime', 'minute', value)}
                isAnsweredExternally={isAnsweredExternally}
              />
            </div>
            {el.isActive &&
              el.startTime.hour != null &&
              (el.endTime.hour === null ||
                (el.endTime.hour === el.startTime.hour && el.endTime.minute === el.startTime.minute)) && (
                <div className="absolute text-red-500 text-xs -bottom-4 right-2">
                  vänligen fyll i din sluttid korrekt
                </div>
              )}
          </div>
        </div>
      ))}
    </div>
  );
};

QuestionWeeklyScheduleType.propTypes = {
  handleClick: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  userAnswers: PropTypes.object,
  isReadOnly: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
  isSummaryView: PropTypes.bool,
};

export default QuestionWeeklyScheduleType;
