import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import tableElements from 'utils/KubenAdmin/UsersPage/tableElements';
import dropdownElements from 'utils/KubenAdmin/UsersPage/dropdownElements';
import {
  modifyedUserRegions as modifyedUserRegionsRecoil,
  userActivity as userActivityRecoil,
  userDetails as userDetailsRecoil,
  userRegions as userRegionsRecoil,
  userSearch as userSearchRecoil,
} from 'state/KubenAdmin/users';
import { templates as templatesRecoil } from 'state/global/templates';
import { jobStatus as jobStatusRecoil } from 'state/global/jobStatus';

import useUserSearch from 'hooks/api/user/useUserSearch';
import useUserUpload from 'hooks/api/account/useUserUpload';
import useFetchJobStatus from 'hooks/api/job/useFetchJobStatus';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UITablePagination from 'components/global/UICommon/UITablePagination';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIInput from 'components/global/UICommon/UIInput';
import UIButtonDropdownGroup from 'components/global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from 'components/global/UIButtons/UIItemDropdown';
import UISpinner from 'components/global/UICommon/UISpinner';
import UITableResultsDropdown from 'components/global/UICommon/UITableResultsDropdown';

import UITableElementWrapper from 'components/KubenAdmin/UITable/UITableElementWrapper';
import UITableElement from 'components/KubenAdmin/UITable/UITableElement';
import UICardWrapper from 'components/KubenAdmin/UIAdmin/UICardWrapper';
import UITable from 'components/KubenAdmin/UITable/UITable';
import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';

const PAGE_SIZE = 49;

const UsersPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loadingModal, setLoadingModal] = useState(false);
  const [stopRefetchingJob, setStopRefetchingJob] = useState(false);
  const [page, setPage] = useState(1);
  const [dataSize, setDataSize] = useState(50);
  const [file, setFile] = useState();
  const hiddenFileInputRef = useRef(null);
  const [data, setData] = useState({
    text: '',
    active: null,
  });

  const {
    isLoading: isUserSearchLoading,
    isFetching,
    isError: isUserSearchError,
    refetch,
  } = useUserSearch(page, dataSize, data);
  const {
    isLoading: updateFileIsLoading,
    isError: updateFileIsError,
    mutate: mutateUpdateFile,
    isSuccess: updateFileSuccess,
    data: userUploadStatus,
  } = useUserUpload(file);
  const { refetch: jobStatusRefetch, isError: isJobStatusError } = useFetchJobStatus(userUploadStatus?.id);
  const searchData = useRecoilValue(userSearchRecoil);
  const jobStatus = useRecoilValue(jobStatusRecoil);

  const resetUserDetails = useResetRecoilState(userDetailsRecoil);
  const resetModifyedUserRegions = useResetRecoilState(modifyedUserRegionsRecoil);
  const resetUsersActivity = useResetRecoilState(userActivityRecoil);
  const resetAllTemplates = useResetRecoilState(templatesRecoil);
  const resetUserRegions = useResetRecoilState(userRegionsRecoil);
  const resetJobStatus = useResetRecoilState(jobStatusRecoil);

  useEffect(() => {
    if (userUploadStatus === null || userUploadStatus === undefined || userUploadStatus.id === undefined) return;
    if (userUploadStatus.progress !== 100) {
      jobStatusRefetch();
    }
  }, [userUploadStatus]);

  useEffect(() => {
    if (Object.keys(jobStatus).length == 0) return;
    if (jobStatus.status !== 'Finished') {
      setTimeout(function () {
        jobStatusRefetch();
      }, 5000);
      return;
    }
    if (jobStatus.status === 'Finished' || stopRefetchingJob) {
      refetch();
      resetUserDetails();
      resetModifyedUserRegions();
      resetUsersActivity();
      resetAllTemplates();
      resetUserRegions();
      resetJobStatus();
      setLoadingModal(false);
      setFile(null);
      return;
    }
  }, [jobStatus]);

  const handleFilesClick = (event, fileId) => {
    hiddenFileInputRef.current.id = fileId;
    hiddenFileInputRef.current.click();
  };

  const selectFileImport = (e) => {
    const targetFile = e.target.files[0];
    if (!targetFile) {
      return;
    }
    const formData = new FormData();
    formData.append('Name', targetFile.name);
    formData.append('File', targetFile);
    const fileId = e.target.id;
    if (fileId !== 'undefined') {
      formData.append('Id', fileId);
    }
    setFile(formData);
  };

  const goToInviteUserPage = () => {
    history.push('/admin/register');
  };

  const changeDisplayValue = (value) => {
    setDataSize(value);
  };

  useEffect(() => {
    refetch();
    resetUserDetails();
    resetModifyedUserRegions();
    resetUsersActivity();
    resetAllTemplates();
    resetUserRegions();
    resetJobStatus();
  }, [page, dataSize]);

  useEffect(() => {
    setTimeout(() => {
      refetch();
      setPage(1);
    }, 500);
  }, [data]);

  useEffect(() => {
    if (!file) {
      return;
    }
    setLoadingModal(true);
    mutateUpdateFile();
  }, [file]);

  useEffect(() => {
    if (updateFileSuccess && !loadingModal) refetch();
  }, [updateFileSuccess]);

  useEffect(() => {
    if (isJobStatusError) {
      setLoadingModal(false);
      setStopRefetchingJob(true);
      setFile(null);
    }
  }, [isJobStatusError]);

  if (updateFileIsLoading) {
    return <UILoadingModal />;
  }

  if (loadingModal) {
    return (
      <UILoadingModal
        isOpen={loadingModal}
        customMessage={`${jobStatus?.progress != null ? `${Math.round(jobStatus?.progress)}%` : '0%'}`}
      />
    );
  }

  if (isUserSearchError || updateFileIsError || isJobStatusError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UITitle
        title="ADMINISTRATION AV ANVÄNDARE"
        isButton={true}
        buttonName="Skapa användare"
        onClick={goToInviteUserPage}
        isSecondButton={true}
        secondButtonName="Importera användare"
        secondButtonOnClick={handleFilesClick}
      />
      <input
        className="hidden"
        type="file"
        ref={hiddenFileInputRef}
        onChange={(e) => selectFileImport(e)}
      />
      <UICardWrapper title="SÖK ANVÄNDARE I DIN(A) REGIONER EFTER NAMN, ANVÄNDARNAMN ELLER EPOST">
        <UIInput
          value={data.text}
          onChange={(e) => setData({ ...data, text: e })}
          type="search"
          placeholder="Användarnamn"
        />
        <UIButtonDropdownGroup
          value={dropdownElements.find((el) => el.value === data.active).title}
          placeholder={''}
          width="w-64"
          absolute="absolute"
          zIndex={'z-30'}
          translate={false}
          style="dark"
        >
          {dropdownElements.map((el, index) => (
            <UIItemDropdown
              value={el.name}
              displayValue={el.title}
              onChange={() => setData({ ...data, active: el.value })}
              key={index}
            />
          ))}
        </UIButtonDropdownGroup>
      </UICardWrapper>
      <div className="my-10 w-full pb-12">
        <UITitle title="ANVÄNDARE" />
        <UITable tableElements={tableElements}>
          {isUserSearchLoading || isFetching ? (
            <UISpinner theme="fast" />
          ) : (
            <>
              {searchData &&
                searchData.map((el, idx) => (
                  <UITableElementWrapper
                    key={idx}
                    isLink={true}
                    link={`/admin/users/${el.id}`}
                  >
                    <UITableElement
                      name={el.id}
                      maxLength={50}
                    />
                    <UITableElement
                      name={el.name}
                      maxLength={50}
                    />
                    <UITableElement
                      name={el.active}
                      isBool={true}
                    />
                  </UITableElementWrapper>
                ))}
            </>
          )}
        </UITable>
        {(searchData.length > PAGE_SIZE || page !== 1) && (
          <div className="flex">
            <UITablePagination
              page={page}
              setPage={setPage}
              pageSize={PAGE_SIZE}
              data={searchData}
            />
            <UITableResultsDropdown
              dataSize={dataSize}
              onChange={changeDisplayValue}
            />
          </div>
        )}
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default UsersPage;
