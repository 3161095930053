import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FiBox, FiClock, FiSearch } from 'react-icons/fi';
import { BsFileEarmarkMinus, BsFileEarmarkPlus, BsFileEarmarkText } from 'react-icons/bs';
import { RxMove } from 'react-icons/rx';
import { MdFileCopy, MdInsertDriveFile, MdPeopleAlt, MdPermContactCalendar, MdPlace, MdStairs } from 'react-icons/md';
import { HiTemplate } from 'react-icons/hi';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  droppableGraphDefinitionItem,
  editGraphLayout,
  graphDefinitionCategories,
  graphDefinitions,
  graphLayout,
} from 'state/KubenDashboard/graphs';
import { currentStartPagePinnedIds, currentUser, startPageItems } from 'state/global/currentUser';
import { IsOverlayActive } from 'state/global/overlay';

import getGraphDefinitionIcon from 'utils/KubenDashboard/getGraphDefinitionIcon';
import { getAppIcon } from 'utils/global/getAppIcon';
import getGraphDefinitionCategoryIcon from 'utils/KubenDashboard/getGraphDefinitionCategoryIcon';

import SideBarMenu from './SideBarMenu';
import SideBarTopPanel from './SideBarTopPanel';
import AppOverlay from './AppOverlay';
import BirthdayOverlay from '../StartPage/BirthdayOverlay';

const SideBarMenuWrapper = ({ children }) => {
  const location = useLocation();
  const isEditLayout = useRecoilValue(editGraphLayout);
  const user = useRecoilValue(currentUser);
  const CURRENT_APP = location.pathname.split('/')[1];
  const isOverlay = useRecoilValue(IsOverlayActive);

  // Start page dependencies
  const startPageComponents = useRecoilValue(startPageItems);
  const [pinnedIds, setPinnedIds] = useRecoilState(currentStartPagePinnedIds);

  // Dashboard page dependencies
  const dashboardGraphCategories = useRecoilValue(graphDefinitionCategories);
  const dashboardGraphDefinitions = useRecoilValue(graphDefinitions);
  const dashboardSetDroppableItem = useSetRecoilState(droppableGraphDefinitionItem);
  const dashboardLayout = useRecoilValue(graphLayout);

  const apps = useMemo(
    () => [
      {
        title: 'Kuben',
        name: '',
        icon: getAppIcon('start'),
        items: startPageComponents
          ?.filter((item) => item.landingPageComponents.filter((item) => item.enabled).length > 0)
          .map((item) => ({
            title: item.title,
            name: item.name,
            icon: getAppIcon(item.name),
            children: item.landingPageComponents
              .filter((item) => item.enabled)
              .filter((item) => (isEditLayout ? !pinnedIds.includes(item.id) : true))
              .map((item) => ({
                title: item.title,
                name: item.name,
                editIcon: <AiOutlinePlusCircle />,
                editClick: () => setPinnedIds([...pinnedIds, item.id]),
              })),
          })),
      },
      {
        title: 'Rapporter',
        name: 'reports',
        items: [],
        icon: getAppIcon('reports'),
      },
      {
        title: 'Bedömningar',
        name: 'assessments',
        icon: getAppIcon('assessments'),
        items: [
          {
            title: 'Sök',
            name: 'search',
            isDisabled: !user.hasAccessToSearch,
            icon: <FiSearch />,
          },
          {
            title: 'Bedömningar',
            children: [
              {
                title: 'Gör en bedömning',
                name: 'choose-person',
                icon: <BsFileEarmarkPlus />,
                isDisabled: user.hasAccessToPersonAssessment,
              },
              {
                title: 'Mina påbörjade',
                name: 'assessment-history/initial',
                icon: <BsFileEarmarkMinus />,
              },
              {
                title: 'Mina tidigare',
                name: 'assessment-history/completed',
                icon: <BsFileEarmarkText />,
              },
            ],
          },
          {
            title: 'Indirekt & övrig tid',
            name: 'choose-template',
            icon: <FiClock />,
          },
        ],
      },
      {
        title: 'Admin',
        name: 'admin',
        hasOverview: false,
        icon: getAppIcon('admin'),
        items: [
          {
            title: 'Användare',
            name: 'users',
            icon: <MdPeopleAlt />,
            isDisabled: !user.hasAccessToAdminPersons,
          },
          {
            title: 'Personer',
            name: 'persons',
            icon: <MdPermContactCalendar />,
            isDisabled: !user.hasAccessToAdminUser,
          },
          {
            title: 'Regioner',
            name: 'regions',
            icon: <MdPlace />,
            isDisabled: !user.hasAccessToAdminRegion,
          },
          {
            title: 'Mallar',
            name: 'templates',
            icon: <HiTemplate />,
            isDisabled: !user.hasAccessToAdminTemplates,
          },
          {
            title: 'Rapporter',
            name: 'reports',
            icon: <MdInsertDriveFile />,
            isDisabled: !user.hasAccessToAdminReports,
          },
          {
            title: 'Filer',
            name: 'files',
            icon: <MdFileCopy />,
            isDisabled: !user.hasAccessToAdminFiles,
          },
          {
            title: 'Verksamhetsvärden',
            name: 'businessvalues',
            icon: <MdStairs />,
            isDisabled: !user.superAdministrator,
          },
        ],
      },
      {
        title: 'Inställningar',
        name: 'settings',
        icon: getAppIcon('settings'),
        items: [],
      },
      {
        title: 'Analytics',
        name: 'analytics',
        icon: getAppIcon('analytics'),
        items: [
          {
            title: 'Gör ett urval',
            name: 'filter',
            icon: <FiBox />,
          },
          {
            title: 'Gör en prediktion',
            name: 'prediction',
            icon: <FiBox />,
          },
          {
            title: 'Gör en uppföljning',
            name: 'saved-selection',
            icon: <FiBox />,
          },
        ],
      },
      {
        title: 'Dashboard',
        name: 'dashboard',
        icon: getAppIcon('dashboard'),
        items: dashboardGraphCategories.map((category) => ({
          title: category.title,
          name: category.id,
          icon: getGraphDefinitionCategoryIcon(category.id),
          children: dashboardGraphDefinitions
            .filter((item) => item.graphCategoryId == category.id)
            .filter((item) =>
              isEditLayout ? !dashboardLayout.map((layoutItem) => layoutItem.i).includes(item.graphId) : true,
            )
            .map((item) => ({
              title: item.menuItemTitle,
              name: item.graphId,
              onDragStart: () => dashboardSetDroppableItem(item),
              icon: getGraphDefinitionIcon(item.graphId),
              editIcon: <RxMove />,
            })),
        })),
      },
    ],
    [
      startPageComponents,
      isEditLayout,
      pinnedIds,
      user,
      dashboardGraphCategories,
      dashboardGraphDefinitions,
      dashboardLayout,
    ],
  );

  const app = apps.find((app) => app.name == CURRENT_APP);

  return (
    <div className="h-screen flex print-overflow">
      {app && (
        <SideBarMenu
          isEditLayout={isEditLayout}
          title={app.title}
          items={app.items}
          name={app.name}
          icon={app.icon}
          hasOverview={app.hasOverview}
        />
      )}
      <div className="flex-1 flex flex-col print-overflow">
        <SideBarTopPanel currentApp={CURRENT_APP} />
        <div
          className="flex-1 app-bg font-inter flex flex-col print-overflow
                 transition-colors duration-200 ease-in-out relative print-bg-white"
        >
          <AppOverlay isVisible={isOverlay} />
          <BirthdayOverlay appName={app?.name} />
          <div
            className="rounded-tl-3xl p-10 content print-scroll flex-1 min-h-0 relative print-this"
            id="content-wrapper"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

SideBarMenuWrapper.propTypes = {
  children: propTypes.node,
};

export default SideBarMenuWrapper;
