import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import DashboardPreviewChartToolTip from 'components/KubenDashboard/Charts/DashboardPreviewChartToolTip';
import formatNumber from 'utils/KubenDashboard/formatNumber';

const PersonChart = ({ chartData, isExpanded, hasAccess = false }) => {
  const formatXAxisTick = (value) => value.substring(0, 3);
  const formatYAxisTick = (value) => `${formatNumber(value)}\u00A0${chartData.units}`;

  const renderGradientDefs = () => (
    <defs>
      {chartData.titles.map((title, idx) => (
        <linearGradient
          key={idx}
          id={`${title.id}colorUv`}
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop
            stopColor={title.color}
            stopOpacity="40%"
          />
          <stop
            offset="95%"
            stopColor={title.color}
            stopOpacity="1%"
          />
        </linearGradient>
      ))}
    </defs>
  );

  const maxTickLength = useMemo(() => {
    const yKeys = Object.keys(chartData.data[0]).filter((key) => key !== 'name' && key !== 'drillDownDataKey');
    const ticks = chartData.data.flatMap((d) => yKeys.map((key) => formatYAxisTick(d[key] || 0)));
    const longestTick = ticks.reduce((a, b) => (a.length > b.length ? a : b), '');
    return longestTick.length;
  }, [chartData]);

  const getLeftMargin = (maxTickLength) => {
    if (!isExpanded) {
      return 30;
    }

    if (maxTickLength >= 10) {
      return 40;
    } else if (maxTickLength > 8) {
      return 25;
    } else if (maxTickLength > 4) {
      return 18;
    } else {
      return 0;
    }
  };

  return (
    <ResponsiveContainer
      className="absolute top-0 right-0"
      width="100%"
      height="100%"
      animationDuration={0}
      debounce={0}
    >
      <AreaChart
        data={chartData.data}
        margin={{ top: isExpanded ? 100 : 5, right: 5, left: getLeftMargin(maxTickLength), bottom: 10 }}
      >
        {hasAccess && <Tooltip content={<DashboardPreviewChartToolTip />} />}
        {renderGradientDefs()}
        {isExpanded && (
          <>
            <XAxis
              dataKey="name"
              tickMargin={10}
              tick={{ fill: 'hsl(0, 0%, 60%)' }}
              axisLine={false}
              tickLine={false}
              tickFormatter={formatXAxisTick}
              tickSize={14}
            />
            <YAxis
              tickFormatter={formatYAxisTick}
              animationDuration={0}
              axisLine={false}
              tickLine={false}
              tickSize={14}
              tick={{ fill: 'hsl(0, 0%, 60%)' }}
            />
          </>
        )}
        {chartData.titles.map((title, idx) => (
          <Area
            key={idx}
            unit={chartData.units}
            type={chartData.options.chartType}
            dataKey={title.id}
            stroke={title.color}
            strokeWidth={3}
            animationDuration={0}
            fill={`url(#${title.id}colorUv)`}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

PersonChart.propTypes = {
  chartData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    titles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      }),
    ).isRequired,
    units: PropTypes.string.isRequired,
    options: PropTypes.shape({
      chartType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  hasAccess: PropTypes.bool.isRequired,
};

export default PersonChart;
