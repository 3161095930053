import React from 'react';
import PropTypes from 'prop-types';
import { BsCopy } from 'react-icons/bs';
import toast from 'react-hot-toast';

const PersonDataRow = ({ label, value, isCopy }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    toast.success(`${label} kopierad!`);
  };

  return (
    <div className="flex flex-col items-start text-left">
      <h2 className="text-base subtitle font-medium">{label}</h2>
      <div className="flex items-center space-x-3">
        <span className="text-lg font-medium break-all">{value}</span>
        {isCopy && (
          <button
            onClick={handleCopy}
            className="border border-tertiary rounded-md px-2 py-1 button-item"
          >
            <BsCopy className="text-sm" />
          </button>
        )}
      </div>
    </div>
  );
};

PersonDataRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  isCopy: PropTypes.bool,
};

export default PersonDataRow;
