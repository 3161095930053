export const getBirthDay = () => {
  return new Date('2014-11-10');
};

export const isWithinBirthdayWeek = () => {
  const today = new Date();
  const birthDay = getBirthDay();

  birthDay.setFullYear(today.getFullYear());

  const birthdayWeekEnd = new Date(birthDay);
  birthdayWeekEnd.setDate(birthDay.getDate() + 7);

  const isWithinBirthdayWeek = today >= birthDay && today <= birthdayWeekEnd;

  return isWithinBirthdayWeek;
};
