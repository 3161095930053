import React from 'react';
import PropTypes from 'prop-types';

const PersonGridCard = ({ title, children, icon, isDataBlurred, className }) => {
  return (
    <div
      className={`flex flex-col background graph-wrapper rounded-xl px-3 py-2 relative
        transition-all ease-out duration-500 h-full w-full ${className} row-span-3`}
    >
      <div className="flex justify-between w-full">
        <div className="flex items-center h-fit space-x-2 w-full">
          {icon && <div className="border border-tertiary rounded p-0.5 h-7 w-7">{icon}</div>}
          <div className="preview-text text-md font-medium flex select-text">{title}</div>
        </div>
      </div>
      <div
        className={`flex h-full w-full justify-between py-3 overflow-y-auto
             ${isDataBlurred ? 'blur-[5px]' : ''}
          `}
      >
        {children}
      </div>
    </div>
  );
};

PersonGridCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  icon: PropTypes.any,
  isDataBlurred: PropTypes.bool,
  className: PropTypes.string,
};

export default PersonGridCard;
