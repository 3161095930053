import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import toast from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import { isConfettiActive } from 'state/global/overlay';
import { getBirthDay, isWithinBirthdayWeek } from 'utils/global/kubenBirthdate';

const BirthdayOverlay = ({ appName }) => {
  const ref = useRef(false);
  const [showConfetti, setShowConfetti] = useRecoilState(isConfettiActive);
  const birthDay = getBirthDay();
  const today = new Date();
  const isBirthDay = isWithinBirthdayWeek();
  const years = today.getFullYear() - birthDay.getFullYear();
  const text = `🎉 Kuben ${years} år 🎉`;
  const isStartPage = appName === '';

  useEffect(() => {
    if (isBirthDay && !ref.current && isStartPage) {
      toast.dismiss();
      toast((t) => (
        <div className="flex space-x-4 relative items-center">
          <h1 className="text-xl text-primary whitespace-nowrap">{text}</h1>
          <button
            onClick={() => handleClose(t.id)}
            className="border rounded p-2"
          >
            Stäng
          </button>
        </div>
      ));
    }
  }, [isBirthDay, text, isStartPage]);

  const handleClose = (toastId) => {
    ref.current = true;
    toast.dismiss(toastId);
    setShowConfetti(true);
  };

  useEffect(() => {
    if (showConfetti) {
      ref.current = true;
      setTimeout(() => {
        setShowConfetti(false);
      }, 1_000);
    }
  }, [showConfetti]);

  if (!isBirthDay) {
    return null;
  }

  return (
    <Confetti
      className="absolute inset-0"
      numberOfPieces={200}
      tweenDuration={1}
      recycle={showConfetti}
      run={ref.current || showConfetti}
    />
  );
};

BirthdayOverlay.propTypes = {
  appName: PropTypes.string.isRequired,
};

export default BirthdayOverlay;
