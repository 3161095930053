import query from '../useQueryWrapper';
import actions from 'api/person';

const useMutateAddPersonTag = () => {
  return query.useMutationWrapper(
    async (data) => {
      const res = await actions.addPersonTag(data);
      return res.data;
    },
    {
      retry: false,
    },
  );
};

export default useMutateAddPersonTag;
