import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

const UIButtonLink = ({ name, link, target = '_self' }) => {
  return (
    <Link
      to={link}
      className="w-40 h-8 text-left title counter text-sm font-semibold
       rounded-md flex items-center justify-between pl-2 button-primary"
      target={target}
    >
      {name}
      <FiArrowRight className="h-6 w-6 pr-1" />
    </Link>
  );
};

UIButtonLink.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  target: PropTypes.string,
};

export default UIButtonLink;
