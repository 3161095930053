/* eslint-disable max-len */
const resources = {
  sv: {
    translation: {
      UIModals: {
        loadingModalMessage: 'Läser in..',
        errorModalMessage: 'Ett oväntat fel inträffade. Gå tillbaka och försök igen.',
        editModalMessage: 'Är du säker på att du vill radera den påbörjade bedömningen? Detta val kan inte ångras.',
        logoutModalMessage: 'Är du säker på att du vill logga ut?',
        logoutModalTitle: 'Logga ut',
        editModalTitle: 'Maximala antal uppnått',
        editModalText:
          'Du har nått det maximala antalet föremål. För att lägga till fler måste du ta bort ett befintligt föremål',
        leaveWithoutSaveingModalMessage: 'Du har inte sparat dina ändringar, är du säker på att du vill lämna sidan?',
      },
      UINameHolder: {
        name: 'Namn',
        gender: 'Kön',
        male: 'Man',
        female: 'Kvinna',
        age: 'Ålder',
        reportId: 'Rapport ID',
        customerNumber: 'Kunder nummer',
      },
      UIBackButton: {
        goBackText: 'Gå tillbaka',
      },
      UIButtons: {
        edit: 'Radera',
        cancel: 'Avbryt',
        logout: 'Logga ut',
        saveStair: 'Spara trappa',
      },
      navItems: {
        start: 'Översikt',
        person: 'Person',
        chooseTemplate: 'Indirekt & övrig tid',
        initialAssessments: 'Mina påbörjade',
        completedAssessments: 'Mina tidigare',
        overview: 'Översikt',
        categoryAssessmentTitle: 'BEDÖMNINGAR',
        categoryAssessmentTitleTid: 'TID',
        search: 'Sök',
        admin: 'Admin',
        raport: 'Rapporter',
        choosePerson: 'Gör en bedömning',
      },
      DashboardPage: {
        news: {
          header: 'Nyheter',
          subheader: 'Senaste nytt från Ensolution',
        },
        header: {
          title: 'Välkommen till Kuben',
          subtitle: 'Framtidens plattform för välfärdsomsorg',
        },
        helpButton: {
          header: 'Behöver du hjälp?',
          buttonText: 'Gå till guide',
        },
        helpButtonShort: {
          title: 'Hjälp',
        },
        sideBar: {
          title: 'Snabbval',
          subtitle: 'Välj vart du vill gå direkt här nedan',
        },
        apps: {
          assessment: 'Kuben Bedömningar',
          reports: 'Kuben Rapporter',
          analytics: 'Kuben Analytics',
        },
        edit: {
          title: 'Du är i läge för redigering.',
          subtitle: 'Ändra placering efter dina önskemål genom att klicka på ',
          subtitleSecondPart: ' eller ',
          editText: 'Anpassa',
          saveEdit: 'Spara  gjorda val',
          cancelEdit: 'Avbryt',
        },
      },
      ChoosePersonPage: {
        heading: 'Vem ska du besöka?',
        fillPersonIdLabel: 'Fyll i personnummer eller ID',
        fillPersonNameLabel: 'Fyll i brukarens för- och efternamn',
        inputIdPlaceholder: 'exempel: 193501014417',
        inputNamePlaceholder: 'exempel: Anders Andersson',
        wrongIdValidationMessage: 'Ogiltigt personnummer.',
        personNotFoundValidationMessage: 'Kunde inte hitta person med detta personnummer.',
        goIndirectLinkText: 'Vill du gå till indirekt och övrig tid?',
        createNewPersonText: 'Lägg till brukare med person nr. ',
        clickHereTextLink: 'klicka här',
        dropdownTextMan: 'Man',
        dropdownTextWoman: 'Kvinna',
        dropdownTextGenderPlaceholder: 'Kön',
        dropdownTextYearPlaceholder: 'Ålder',
        needHelpTextLink: 'Behöver du hjälp?',
        nextButtonText: 'Gå vidare',
        helpText:
          'Här fyller du i individens personnummer (enligt formatet ÅÅÅÅMMDDNNNN), annat externt ID eller det pseudonymiserade Kuben-ID:et.',
      },
      ChooseAssessmentsPage: {
        heading: 'Översikt bedömningar',
        subHeader: 'Välj vad du vill göra',
        createNewAssessmentTitle: 'Ny bedömning',
        previousAssessmentsTitle: 'Tidigare bedömningar',
        continuePreviousAssessmentTitle: 'Påbörjad bedömning',
        createNewAssessmentText: 'Välj en av mallarna och svara på frågorna.',
        previousAssessmentsText: 'Se och redigera tidigare bedömningar som gjort på brukare.',
        continuePreviousAssessmentText: 'Fortsätt frågorna från påbörjad bedöming.',
      },
      ChooseTemplatePage: {
        templateHeading: 'Välj mall här nedan',
        templateNameLabel: 'Namn',
        templateCategoryLabel: 'Kategori',
        templateTitle: 'Mallar',
        regionHeading: 'Välj region här nedan',
        regionPlaceholder: 'Region',
        regionTitle: 'Region',
        currentStepText: 'Steg',
        totalStepsText: 'av',
        searchPlaceholder: 'Sök',
        sortPlaceholder: 'Sortera',
        dropdownTextSortAlphabetical: 'Alfabetisk',
        dropdownTextSortCategory: 'Kategori',
        dropdownTextSortLatest: 'Senaste',
      },
      QuestionsPage: {
        currentQuestionNumberText: 'Fråga',
        totalQuestionsNumberText: 'av',
        assessedAnswerText: 'Bedömt',
        desirableAnswerText: 'Avsett',
        answeredQuetionsText: 'valda',
        mallText: 'Mall: ',
        nextButtonText: 'Nästa',
        prevButtonText: 'Föregående',
        minimalizeButtonText: 'Minimera',
        answerDescriptionButtonText: 'Visa svarsbeskrivning',
        openTabNavigationButtonText: 'Navigeringsvy',
        navigationTabTitle: 'Bedömningsordning',
        closeCommentModalButtonText: 'Stäng',
        addCommentModalButtonText: 'Lägg till kommentar',
        deleteCommentModalButtonText: 'Ta bort kommentar',
        modifyCommentModalButtonText: 'Spara ändring',
        descriptionAddCommentModalText: 'Skriv en kommentar',
        descriptionModifyCommentModalText: 'Ändra kommentar',
        commentModalInputTitle: 'Kommentarer',
        commentsButtonText: 'Visa kommentar',
        goToSummaryLinkText: 'Gå till sammanfattning',
        answerDescriptionTitle: 'Svarsbeskrivningar',
        specifyDesirableCheckboxText: 'Ange även avsett',
        showOnlyDefaultCheckboxText: 'Visa enbart ändrade svar',
      },
      SummaryPage: {
        summaryHeading: 'Sammanfattning',
        healthcareUnitText: 'Enhet:',
        totalAssessmentText: 'Summa bedömning',
        hoursPerWeekText: 'Timmar per vecka',
        userEstimatedTime: 'Indirekt brukartid',
        totalHours: 'Timmar',
        totalTimePercents: 'procent',
        descriptionLabel: 'Beskrivning:',
        yourAnswerLabel: 'Ditt svar:',
        desirableAnswerLabel: 'Avsett:',
        currentAnswerLabel: 'Bedömt:',
        startDateLabel: 'Startdatum:',
        endDateLabel: 'Stoppdatum:',
        dateLabel: 'Datum:',
        showMore: 'Visa mer',
        showLess: 'Visa mindre',
        noAnswer: 'Ej besvarad',
        sendButton: 'Färdigställ bedömning',
        updateButton: 'Spara revidering',
        goTopButton: 'Till start av sammanfattning',
        clearDate: 'Rensa datum',
      },
      SummaryEstimatedCard: {
        totalHoursPerWeek: 'Totalt använt tid per vecka',
        totalPercentsPerWeek: 'Totalt använt tid i procent per vecka',
        totalHoursPerWorkingWeek: 'Totalt använt tid i arbetsdagar per vecka',
      },
      SummaryMultiAnswerType: {
        yourAnswerLabel: 'Ditt svar',
      },
      SummaryFrequencyType: {
        frequencyLabel: 'Frekvens',
      },
      QuestionTimeType: {
        minutesDropdownLabel: 'Minuter',
        hoursDropdownLabel: 'Timmar',
        timePeriodDropdownLabel: 'Period',
      },
      QuestionFrequencyType: {
        rangeText: 'mellan',
        frequencyLabel: 'Antal',
      },
      DynamicValues: {
        Man: 'Man',
        Kvinna: 'Kvinna',
        Alfabetisk: 'Alfabetisk',
        Kategori: 'Kategori',
        Senaste: 'Senaste',
        HalfDay: 'Halvdag',
        Day: 'Dag',
        Week: 'Vecka',
        Month: 'Månad',
        Quarter: 'Kvartal',
        Completed: 'Avliden',
        Deceased: 'Avslutad insats',
        Reassessment: 'Ombedömning',
        Other: 'Annan orsak',
      },
      AssessmentHistoryPage: {
        table: {
          titlePersonAssessments: 'Tidigare bedömningar',
          titleCompletedAssessments: 'Mina tidigare bedömningar',
          titleInitialAssessments: 'Mina påbörjade bedömningar',
          templateLabel: 'Mall',
          executorLabel: 'Utförare',
          userLabel: 'Brukare',
          assessmentDateLabel: 'Bedömningsdatum',
          startStopDateLabel: 'Start/stopp datum',
          bedLabel: 'Bed.',
          notLabel: 'Not.',
        },
        navigation: {
          showText: 'Visar',
          ofText: 'av',
          resultText: 'resultat',
        },
        actionModal: {
          changeText: 'Revidera',
          reassessText: 'Ändra',
          showSummary: 'Visa översikt',
          changeStopDateText: 'Ändra stoppdatum',
          deleteAssessment: 'Radera',
          goToPerson: 'Gå till person',
        },
        dateModal: {
          titleText: 'Ändra stoppdatum',
          descriptionTextPart1: 'Vill du sätta stoppdatum på bedömning gjord',
          descriptionTextPart2: 'på mallen',
          descriptionTextPart3: 'Bedömingen avser region',
          descriptionTextPart4: 'och enhet',
          clearDateText: 'Inget datum',
          causeText: 'Orsak',
          dropdownOptions: {
            completedText: 'Avliden',
            deceasedText: 'Avslutad insats',
            reassessmentText: 'Ombedömning',
            otherText: 'Annan orsak',
          },
          submitText: 'Spara',
          cancelText: 'Avbryt',
        },
      },
      AssessmentDashboardPage: {
        welcomeText: 'Välkommen',
        whatDoYouWantQuestion: 'Vad vill du göra?',
        needHelpTextLink: 'Behöver du hjälp?',
        links: {
          assessments: 'Gör en bedömning',
          timming: 'Indirekt & Övrig tid',
          search: 'Sök',
          seeAll: 'Visa alla',
        },
        startedAssessment: {
          header: 'Mina påbörjade bedömningar',
          deleteText: 'Radera',
          continueText: 'Fortsätt påbörjad',
          noDataText: 'Du har ingen påbörjad bedömning',
        },
        finishedAssessment: {
          header: 'Mina tidigare bedömningar',
          noDataText: 'Du har ingen slutförd bedömning',
          startDateText: 'Start datum: ',
          endDateText: 'Stop datum: ',
        },
        tableItems: {
          user: 'BRUKARE',
          template: 'MALL',
          unit: 'ENHET',
          date: 'DATUM',
        },
      },
      ReportsPage: {
        titleText: 'Rapporter',
        infoText: 'Följ stegen nedan för att hämta en rapport.',
        raportTypeText: 'Rapporttyp',
        templateText: 'Mall',
        templateModalTitle: 'Mallar',
        regionText: 'Region',
        changeText: 'Ändra',
        RaportTypeHelpText:
          'Här väljer du vilken typ av rapport som du vill exportera. Exempelvis en statisk export av rådata (såsom bedömningsdata) eller en rapport som visar fördelningen av resurser (resursfördelningsrapport).',
        dateHelpText:
          'Från och med/till och med datum definierar starten och slutet för en rapportperiod, dvs för vilken period du vill se ett resultat. Vid export av rådatarapporter tar systemet med hjälp av från och med/till och med datum ut bedömningar som är sparade under en viss period. Exempelvis om du vill se vilka bedömningar som har sparats under januari månad så väljer du 202x-01-01 - 202x-01-31. Vid export av resursfördelningsrapporter tar systemet med hjälp av från och med/till och med datum bara ut bedömningar som är aktiva under rapportperioden. Exempelvis om du vill se behovet av resurser för januari månad så väljer du 202x-01-01 - 202x-01-31.',
        dateText: 'Från och till datum',
        saveText: 'Spara',
        cancelText: 'Avbryt',
        useDate: 'Använd från och till datum',
        recalculateLevelText: 'Beräkna om nivå',
        latestAssessmentCheckboxLabel: 'Enbart senaste bedömningen',
        pseudonymizedDataCheckboxLabel: 'Pseudonymiserad data',
        chooseLinkText: 'Klicka här för att välja',
        retrieveSubmitButonText: 'Hämta',
        clearSelectedText: 'Återställ alla val',
      },
      SearchPage: {
        returnToSearchText: 'Gör en ny sökning',
        newSearchText: 'Ny sökning',
        searchByText: 'Sök på handläggare alternativt brukares namn, personummer eller ID',
        criteriaText: 'Kriterier',
        unitText: 'Enhet',
        latestResultText: 'Enbart senaste bedömningen',
        recalculateText: 'Räkna om nivå',
        clearText: 'Nollställ',
        searchText: 'Sök',
        tableTitleAssessmentsText: 'Resultat',
        noResultsFoundText: 'Din sökning gav inga resultat',
        searchInfo: 'Klicka på knappen till höger för att göra en ny sökning',
      },
      ErrorPage: {
        error403Title: 'Vi är ledsna...',
        error403Desc: 'Du saknar behörighet att komma åt denna sidan.',
        error404Title: 'Har du hamnat fel?',
        error404Desc: 'Sidan du söker hittades inte.',
        error500Title: 'Ett oväntat fel inträffade',
        error500Desc: 'Vi jobbar på att lösa problemet.',
        error401Title: 'Du har loggats ut',
        error401Desc: 'Du har varit inaktiv i mer än en timme.',
        error401Button: 'Logga in igen',
        returnToStart: 'Gå till startsida',
      },
      Generic: {
        dropdownPlaceholder: 'Välj',
      },
      BusinessValuesPage: {
        missingUnitTypesText: 'Verksamhetsområdet saknar enhetstyper. Klicka på ny enhetstyp för att lägga till',
        missingNightStaffingsText:
          'Verksamhetsområdet saknar nattbemanning. Klicka på ny nattbemanning för att lägga till',
        missingLevelsText: 'Verksamhetsområdet saknar nivåer. Klicka på ny nivå för att lägga till',
        missingNightStaffingStaircaseText: 'Det här verksamhetsområdet saknar nattbemanningstrappa',
        missingStaffingstaircaseText: 'Det här verksamhetsområdet saknar bemanningstrappa',
      },
    },
  },
};

export default resources;
